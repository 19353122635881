<template>
  <div class="bt-information-list" :style="'min-height:' + height + 'px'">
    <div class="bt-main-container">
      <div class="bt-title">新闻资讯<span class="bt-subtitle">NEWS  INFORMATION</span></div>
      <ul class="bt-information-list">
        <li v-for="(item, index) in informationList" :key="index" @click="informationChange(item)">
          <div class="bt-information-date">{{item.date}}</div>
          <div class="bt-information-title">{{item.title}}</div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { list } from './list.js';
export default {
  data() {
    return {
      height: window.innerHeight - 60,
      informationList: list,
    }
  },
  created() {
    
  },
  methods: {
    // 列表点击
    informationChange(item) {
      this.$router.push({
        name: 'informationDetail',
        query: {
          id: item.id
        }
      })
    }
    
  }
}
</script>

<style scoped lang="scss">
  .bt-information-list {
    // background-image: url('../../assets/images/product-bg.png');
    // background-repeat: no-repeat;
    // background-size: cover;
    overflow: auto;
    background-color: #fff;
    padding-bottom: 60px;
  }

  .bt-information-list {
    li {
      display: flex;
      line-height: 32px;
      color: #666;
      cursor: pointer;
      &:hover {
        color: $themes_color;
      }
    }
    .bt-information-date {
      margin-right: 20px;
    }
  }


@media Screen and (max-width: $screen_width) {
  .bt-information-list {
    min-height: auto !important;
  }
  .bt-information-list {
    li {
      display: block;
      margin-bottom: 6px;
      line-height: 16px;
      font-size: 12px;
    }
    .bt-information-title {
      display: inline-block;
      width: calc(100% - 80px);
      vertical-align: top;
    }
    .bt-information-date {
      margin-right: 0;
      display: inline-block;
      width: 80px;
      vertical-align: top;
    }
  }
}


</style>