<template>
  <div class="bt-product" :style="'min-height:' + height + 'px'">
    <div class="bt-main-container">
      <div class="bt-title">核心产品<span class="bt-subtitle">CORE PRODUCTS</span></div>
      <ul class="bt-list">
        <li v-for="(item, index) in productList" :key="index" @click="productChange(item)">
          <el-image
            class="bt-business-image"
            :src="item.logo"
            fit="fill"></el-image>
          <div class="bt-detail">
            <div class="bt-name">{{item.name}}</div>
            <div class="bt-name">{{item.context}}</div>
            <el-button type="primary" plain>查看更多<i class="el-icon-d-arrow-right"></i></el-button>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { list } from './list.js';
export default {
  data() {
    return {
      height: window.innerHeight - 60,
      productList: list,
    }
  },
  mounted() {
    
  },
  methods: {

    // 点击查看更多
    productChange(item) {
      this.$router.push({
        name: 'productsDetail',
        query: {
          id: item.id
        }
      })
    }
    
  }
}
</script>

<style scoped lang="scss">
  .bt-product {
    background-image: url('../../assets/images/product-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
  }
  .bt-list {
    padding-bottom: 30px;
    display: flex;
    flex-wrap: wrap;
    margin-left: -50px;
    margin-right: -50px;
    li {
      position: relative;
      width: calc(50% - 100px);
      height: 550px;
      margin: 50px;
      background-color: #fff;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      overflow: hidden;
      text-align: center;
      cursor: pointer;
      transition: all 0.4s;
      &:hover {
        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.4);
        .el-image {
          margin-top: 20px;
          transform: scale(0.6);
        }
        .bt-detail {
          bottom: 0;
        }
      }
    }
    .el-image {
      margin-top: 120px;
      vertical-align: middle;
      height: 300px;
      transition: all 0.4s;
    }
  }
  
  .bt-detail {
    position: absolute;
    bottom: -250px;
    left: 0;
    width: 100%;
    height: 200px;
    padding-top: 50px;
    background-color: $themes_color;
    text-align: left;
    transition: all 0.4s;
    .bt-name {
      margin-top: 10px;
      margin-left: 100px;
      font-size: 26px;
      color: #fff;
    }
    .el-button {
      margin-left: 100px;
      margin-top: 20px;
      background-color: #fff !important;
      color: $themes_color !important;
      text-align: left;
      i {
        margin-left: 4px;
        font-size: 16px;
      }
    }
  }

@media Screen and (max-width: $screen_width) {
  .bt-list {
    margin-left: 0;
    margin-right: 0;
    li {
      margin: 10px auto;
      width: 300px;
      height: 300px;
    }
    .el-image {
      margin-top: 60px;
      height: 150px;
    }
  }
  .bt-detail {
    height: 120px;
    padding-top: 20px;
    .bt-name {
      margin-left: 50px;
      font-size: 16px;
    }
    .el-button {
      margin-top: 10px;
      padding: 6px 10px;
      margin-left: 50px;
      i {
        font-size: 14px;
      }
    }
  }

}

</style>