import axios from 'axios' // 引入axios
// import store from '../store/index' // 引入Vuex
// import router from '../router' // 引入vue-router
import { Message } from 'element-ui' //局部引入UI框架组件
// import Qs from 'qs'
// import { baseUrl } from './baseUrl'


//引入nprogress
import NProgress from 'nprogress' // 进度条
import 'nprogress/nprogress.css' //这个样式必须引入

// 请求地址
// axios.defaults.baseURL = baseUrl
axios.defaults.baseURL = '/'

// 请求超时时间
axios.defaults.timeout = 20000;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

// 配置 CORS 跨域
axios.defaults.withCredentials = true;
axios.defaults.crossDomain = true;

//http request 拦截器
axios.interceptors.request.use(
  config => {
    if(config.method == 'post') {
      config.data = { ...config.data }
    } else if(config.method == 'get') {
      config.params = { ...config.params }
    }
    config.data = JSON.stringify(config.data);
    config.headers = {
      'Content-Type': 'application/json',
      // 'Content-Type': 'application/x-www-form-urlencoded'
    }
    if(JSON.parse(localStorage.getItem('userInfo'))) {
      config.headers['Authorization'] = JSON.parse(localStorage.getItem('userInfo')).token
    }
    NProgress.start()
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// 响应拦截器
axios.interceptors.response.use(
  // 服务码是200的情况
  response => {
    NProgress.done()
    Promise.resolve(response)
    // 错误码不为0时抛出错误及错误提示，为0时显示正确
    if (response.data.errCode == 0) {
      return Promise.resolve(response.data);
    } else {
      Message({
        message: response.data.msg,
        type: 'success',
      });
      return Promise.reject(response)
    }
  },
  // 服务器状态码不是200的情况
  error => {
    NProgress.done()
    if (error.response) {
      switch (error.response.status) {
        // 404请求不存在
        case 404:
          Message({
            message: '网络请求不存在',
            type: 'error',
          });
          break;
        // 其他错误，直接抛出错误提示
        default:
          Message({
            message: error.data.error,
            type: 'error',
          });
      }
      return Promise.reject(error.data)
    }
  }
)

// /**
//  * 请求方法
//  * @param {String} url [请求的url地址]
//  * @param {Object} params [请求时携带的参数]
//  * @param {Object} config [请求时url后直接加的参数，默认为空]
//  */
// export function request(method = 'POST', url, data) {
//   if(method == 'GET') {
//     return new Promise((resolve, reject) => {
//       axios.get(baseUrl+url,{
//         params: data
//       }).then(res => {
//         resolve(res)
//       }).catch(err => {
//         reject(err)
//       })
//     })
//   } else if(method == 'POST') {
//     return new Promise((resolve, reject) => {
//       axios.post(baseUrl+url, data)
//         .then(res => {
//           resolve(res)
//         })
//         .catch(err => {
//           reject(err)
//         })
//     })
//   }
// }

// get
export function get(url, data) {
  return new Promise((resolve, reject) => {
    axios.get(url,{
      params: data
    }).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}

// post
export function post(url, data) {
  return new Promise((resolve, reject) => {
    axios.post(url, data)
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}

// delete
export function del(url, data) {
  return new Promise((resolve, reject) => {
    axios.delete(url, data)
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}

// put
export function put(url, data) {
  return new Promise((resolve, reject) => {
    axios.put(url, data)
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}

