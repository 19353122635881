// 公司基本信息数量
export const baseNumberList = [
  {name: '公司营业额', number: 3, unit: '亿元'},
  {name: '员工人数', number: 301, unit: '人'},
  {name: '托管三甲医院', number: 10, unit: '家'},
  {name: '托管二级以上医院', number: 30, unit: '家'},
]

// 发展历程
export const historyList = [
  {time: '2002', context: '始创于云南宣威，从事医用耗材的配送业务，根植于云南医疗市场'},
  {time: '2010', context: '进军云南曲靖，扩大医疗耗材的配送业务，实现了曲靖市场全覆盖。同时正式开始第三方全院医疗设备维保服务'},
  {time: '2013', context: '依托于耗材配送合作医院，开始涉足医疗设备维保业务'},
  {time: '2015', context: '正式将第三方全院医疗设备维保服务确定为公司长期发展战路，并开始业务转型'},
  {time: '2016', context: '基于对行业和科技的发展趋势洞察，启动医疗设备管理系统的自主开发并布局业务'},
  {time: '2017', context: '在云南昆明设立区域总部'},
  {time: '2018', context: '根据维保业务发展战略，为完善业务闭环，把握医疗设备质控，收购第三方检验检测机构-健禾科技。公司业务扩大到医疗设备的计量检测，放射设备的性能检测，辐射安全评价等领域'},
  {time: '2019', context: '深耕云南市场，协同发展全国市场的市场策略，覆盖医疗设备第三方检测、医疗设备生命周期信息系统、医疗器械高值耗材供应和配送的全面业务体系'},
  {time: '2023', context: '全国合伙人布局，百人白仓战略部署全面协同，医疗设备维保业务和医疗耗材智能化管理迈向国际化'},
]

// 企业文化
export const cultureList = [
  {
    name: '理念 | Concept',
    icon: require('@/assets/culture/concept.svg'),
    children: [
      {name: '以医疗技术发展为核心'},
      {name: '让医疗服务更安全有效'},
    ]
  },
  {
    name: '愿景 | Vision',
    icon: require('@/assets/culture/vision.svg'),
    children: [
      {name: '提供惠及全民的医疗服务'},
      {name: '助力医卫行业信息技术发展'},
    ]
  },
  {
    name: '使命 | Mission',
    icon: require('@/assets/culture/mission.svg'),
    children: [
      {name: '让医疗工程人员进入'},
      {name: '高效高层次生活'},
    ]
  },
  {
    name: '价值观 | Values',
    icon: require('@/assets/culture/values.svg'),
    children: [
      {name: '在分享中成长  在内修中完美'},
      {name: '在合作中强盛  在规则中久远'},
    ]
  },
]

// 企业荣誉证书
export const honorMainList = [
  {
    name: '维尔高新技术企业证书',
    image: 'https://image.baitianjituan.com/honor0_0.jpg',
  },
  {
    name: '中国设备维修安装企业能力等级证书',
    image: 'https://image.baitianjituan.com/honor0_1.jpg',
  },
  {
    name: '大理大学培训基地',
    image: 'https://image.baitianjituan.com/honor0_2.jpg',
  },
  {
    name: '云南省培训基地',
    image: 'https://image.baitianjituan.com/honor0_3.jpg',
  }
]

// 企业荣誉证书 全屏查看
export const honorMainProList = [
  'https://image.baitianjituan.com/honor0_0.jpg',
  'https://image.baitianjituan.com/honor0_1.jpg',
  'https://image.baitianjituan.com/honor0_2.jpg',
  'https://image.baitianjituan.com/honor0_3.jpg',
]

// 系列软件著作权证书
export const honorSoftList = [
  'https://image.baitianjituan.com/honor0_4.jpg',
  'https://image.baitianjituan.com/honor0_5.jpg',
  'https://image.baitianjituan.com/honor0_6.jpg',
  'https://image.baitianjituan.com/honor0_7.jpg',
  'https://image.baitianjituan.com/honor0_8.jpg',
  'https://image.baitianjituan.com/honor0_9.jpg',
  'https://image.baitianjituan.com/honor0_10.jpg',
]

// 系列ISO质量管理体系认证证书
export const honorISOList = [
  'https://image.baitianjituan.com/honor0_11.jpg',
  'https://image.baitianjituan.com/honor0_12.jpg',
  'https://image.baitianjituan.com/honor0_13.jpg',
  'https://image.baitianjituan.com/honor0_14.jpg',
  'https://image.baitianjituan.com/honor0_15.jpg',
  'https://image.baitianjituan.com/honor0_16.jpg',
]


// 企业资质
export const enterpriseMainList = [
  {
    name: '集团营业执照',
    image: 'https://image.baitianjituan.com/enterprise0_0.jpg',
  },
  {
    name: '集团药品经营许可证',
    image: 'https://image.baitianjituan.com/enterprise0_1.jpg',
  },
  {
    name: '集团医疗器械经营许可证',
    image: 'https://image.baitianjituan.com/enterprise0_2.jpg',
  },
  {
    name: '维尔医疗器械经营许可证',
    image: 'https://image.baitianjituan.com/enterprise0_3.jpg',
  },
  {
    name: '健禾科技-检验检测机构资质认定证书',
    image: 'https://image.baitianjituan.com/enterprise0_4.jpg',
  },
  {
    name: '维尔医疗器械经营许可证',
    image: 'https://image.baitianjituan.com/enterprise0_5.jpg',
  },
  {
    name: '维尔AAA信用证书',
    image: 'https://image.baitianjituan.com/enterprise0_6.jpg',
  },
  {
    name: '维尔立信单位证书',
    image: 'https://image.baitianjituan.com/enterprise0_7.jpg',
  },
]
// 企业资质全屏查看列表
export const enterpriseMainProList = [
  'https://image.baitianjituan.com/enterprise0_0.jpg',
  'https://image.baitianjituan.com/enterprise0_2.jpg',
  'https://image.baitianjituan.com/enterprise0_3.jpg',
  'https://image.baitianjituan.com/enterprise0_4.jpg',
  'https://image.baitianjituan.com/enterprise0_5.jpg',
  'https://image.baitianjituan.com/enterprise0_6.jpg',
  'https://image.baitianjituan.com/enterprise0_7.jpg',
]

// 维尔系列服务/诚信认证证书
export const enterpriseSeviceList = [
  'https://image.baitianjituan.com/enterprise0_8.jpg',
  'https://image.baitianjituan.com/enterprise0_9.jpg',
  'https://image.baitianjituan.com/enterprise0_10.jpg',
  'https://image.baitianjituan.com/enterprise0_11.jpg',
]

// 合作医院
export const hospitalList = [
  {
    image: require('@/assets/hospital/昆明医科大学第一附属医院.png'),
    name: '昆明医科大学第一附属医院'
  },
  {
    image: require('@/assets/hospital/昆明医科大学第二附属医院（工人医院）.png'),
    name: '昆明医科大学第二附属医院（工人医院）'
  },
  {
    image: require('@/assets/hospital/中国人民解放军联勤保障部队第九二〇医院.png'),
    name: '中国人民解放军联勤保障部队第九二〇医院'
  },
  {
    image: require('@/assets/hospital/云南省老年病医院.png'),
    name: '云南省老年病医院'
  },
  {
    image: require('@/assets/hospital/天津市武清区第二人民医院.png'),
    name: '天津市武清区第二人民医院'
  },
  {
    image: require('@/assets/hospital/抚州市第二人民医院.png'),
    name: '抚州市第二人民医院'
  },
  {
    image: require('@/assets/hospital/潜江市中医院.png'),
    name: '潜江市中医院'
  },
  {
    image: require('@/assets/hospital/襄阳市中西医结合医院.png'),
    name: '襄阳市中西医结合医院'
  },
  {
    image: require('@/assets/hospital/襄阳市妇幼保健院.png'),
    name: '襄阳市妇幼保健院'
  },
  {
    image: require('@/assets/hospital/保定市第二医院.png'),
    name: '保定市第二医院'
  },
  {
    image: require('@/assets/hospital/宣威市云峰医院.png'),
    name: '宣威市云峰医院'
  },
  {
    image: require('@/assets/hospital/宣威市中医医院.png'),
    name: '宣威市中医医院'
  },
  {
    image: require('@/assets/hospital/宣威市第一人民医院.png'),
    name: '宣威市第一人民医院'
  },
  {
    image: require('@/assets/hospital/曲靖市妇幼保健院.png'),
    name: '曲靖市妇幼保健院'
  },
  {
    image: require('@/assets/hospital/曲靖市第一人民医院.png'),
    name: '曲靖市第一人民医院'
  },
  {
    image: require('@/assets/hospital/玉溪市妇幼保健院.png'),
    name: '玉溪市妇幼保健院'
  },
  {
    image: require('@/assets/hospital/楚雄市人民医院.png'),
    name: '楚雄市人民医院'
  },
  {
    image: require('@/assets/hospital/丽江市妇幼保健院.png'),
    name: '丽江市妇幼保健院'
  },
  {
    image: require('@/assets/hospital/丽江市人民医院.png'),
    name: '丽江市人民医院'
  },
  {
    image: require('@/assets/hospital/安宁市中医医院.png'),
    name: '安宁市中医医院'
  },
  {
    image: require('@/assets/hospital/安宁市第一人民医院.png'),
    name: '安宁市第一人民医院'
  },
  {
    image: require('@/assets/hospital/文山州人民医院.png'),
    name: '文山州人民医院'
  },
  {
    image: require('@/assets/hospital/昭通市昭阳区中医医院.png'),
    name: '昭通市昭阳区中医医院'
  },
  {
    image: require('@/assets/hospital/富源县人民医院.png'),
    name: '富源县人民医院'
  },
  {
    image: require('@/assets/hospital/富源县妇幼保健计划生育服务中心.png'),
    name: '富源县妇幼保健计划生育服务中心'
  },
  {
    image: require('@/assets/hospital/富源县中医医院.png'),
    name: '富源县中医医院'
  },
  {
    image: require('@/assets/hospital/师宗县人民医院.png'),
    name: '师宗县人民医院'
  },
  {
    image: require('@/assets/hospital/会泽县中医医院.png'),
    name: '会泽县中医医院'
  },
  {
    image: require('@/assets/hospital/会泽县者海医院.png'),
    name: '会泽县者海医院'
  },
  {
    image: require('@/assets/hospital/会泽兴仁医院.png'),
    name: '会泽兴仁医院'
  },
  {
    image: require('@/assets/hospital/易门县妇幼保健院.png'),
    name: '易门县妇幼保健院'
  },
  {
    image: require('@/assets/hospital/麻栗坡县人民医院.png'),
    name: '麻栗坡县人民医院'
  },
  {
    image: require('@/assets/hospital/沧源县人民医院.png'),
    name: '沧源县人民医院'
  },
  {
    image: require('@/assets/hospital/石林县人民医院.png'),
    name: '石林县人民医院'
  },
  {
    image: require('@/assets/hospital/播州区人民医院.png'),
    name: '播州区人民医院'
  },
  {
    image: require('@/assets/hospital/务川县人民医院.png'),
    name: '务川县人民医院'
  },
  {
    image: require('@/assets/hospital/浠水县中医院.png'),
    name: '浠水县中医院'
  },
  {
    image: require('@/assets/hospital/广昌县人民医院.png'),
    name: '广昌县人民医院'
  },
  {
    image: require('@/assets/hospital/黎川县中医医院.png'),
    name: '黎川县中医医院'
  }
]