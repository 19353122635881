
<template>
  <div class="header bt-main-container">
    <div class="logo" @click="$router.push('/home')">
      <el-image
      style="margin-top: 10px; height: 40px"
      :src="isHomeFirst ? logoHomeSrc : logoSrc"
      fit="flii"></el-image>
    </div>
    <Aside v-if="!isScreenWidth"></Aside>
    <span v-if="isScreenWidth" :style="{'color': isHomeFirst ? primaryColor : '#fff'}" class="bt-zoom el-icon-s-fold" 
    @click="drawer = true"></span>
    <el-drawer
      title="我是标题"
      :modal="false"
      :visible.sync="drawer"
      :size="250"
      :with-header="false">
      <Aside @menuChange="drawer = false"></Aside>
    </el-drawer>
  </div>
</template>
<script>
import { screenWIidth, primaryColor } from '@/utils/baseText';
import Aside from './aside';
export default {
  components: {
    Aside
  },
  data() {
    return {
      logoSrc: require('@/assets/images/logo.png'),
      logoHomeSrc: require('@/assets/images/logo_home.png'),
      drawer: false,
      isScreenWidth: false,
      primaryColor: primaryColor
    }
  },
  computed: {
    isHomeFirst() {
      return this.$store.state.isHomeFirst
    }
  },
  mounted() {
    if(document.body.clientWidth <= screenWIidth) {
      this.isScreenWidth = true;
    }
    
  },
  methods: {

  }
}
</script>

<style scoped lang="scss">
  .header {
    line-height: 60px;
    text-align: center;
    background-color: transparent;
  }
  
  .logo {
    margin-right: 100px;
    display: inline-block;
    vertical-align: top;
    font-size: 24px;
    color: $themes_color;
    cursor: pointer;
    img {
      display: inline-block;
      vertical-align: top;
      width: 30px;
    }
    span {
      margin-left: 10px;
      display: inline-block;
      vertical-align: top;
    }
  }


@media Screen and (max-width: $screen_width) {
  .bt-zoom {
    display: block;
    float: right;
    margin-top: 12px;
    font-size: 32px;
    color: #fff;
  }
  .logo {
    margin-right: 0;
  }
}

</style>