<template>
  <div class="aside" :class="{'screen-width': isScreenWidth}">
    <el-menu
      class="menu"
      :mode="isScreenWidth ? 'vertical' : 'horizontal'"
      :background-color="backgroundColor"
      :default-active="asideActive"
      :text-color="isHomeFirst ? primaryColor : '#fff'"
      :active-text-color="isHomeFirst ? primaryColor : '#fff'"
      :default-openeds="defaultOpeneds">
      <template v-for="item in menuOptions">
        <el-submenu v-if="item.children" :index="'/' + item.path" :key="item.id">
          <template slot="title">
            <span @click="menuChange(item)">{{item.name}}</span>
          </template>
          <el-menu-item-group>
            <el-menu-item v-for="child in item.children" 
              :key="child.id" :index="'/' + child.path"
              @click="menuChange(child)">
              {{child.name}}
            </el-menu-item>
          </el-menu-item-group>
        </el-submenu>
        <el-menu-item v-else :index="'/' + item.path" :key="item.id" @click="menuChange(item)">
          <span slot="title">{{item.name}}</span>
        </el-menu-item>
      </template>
    </el-menu>
  </div>
</template>
<script>

import { screenWIidth, primaryColor } from '@/utils/baseText';
import { menuList } from '@/utils/menu'
export default {
  components: {
  },
  data() {
    return {
      backgroundColor: 'transparent', //背景颜色
      activeColor: '#ffd04b',  //菜单选中颜色
      menuOptions: menuList, // 菜单列表
      asideActive: '', //默认选中
      isScreenWidth: false,
      defaultOpeneds: [], // 打开的项
    }
  },
  computed: {
    primaryColor() {
      return this.$store.state.primaryColor
    },
    isHomeFirst() {
      return this.$store.state.isHomeFirst
    },
  },
  mounted() {
    this.asideActive = this.$route.path;
    let _route = this.$route;
    this.$store.commit('isHomeFirst', _route.name == 'home' && (_route.query.id == '0' || _route.query.id == undefined) ? true : false );

    if(document.body.clientWidth <= screenWIidth) {
      this.isScreenWidth = true;
      this.backgroundColor = primaryColor
    }

    if(this.isScreenWidth) {
      this.defaultOpeneds = menuList.map(item => {
        return '/' + item.path;
      })
    }
    
  },
  methods: {
    // 菜单列表点击
    menuChange(item) {
      this.$router.push({
        name: item.path,
        query: {
          id: item.serviceId
        }
      })
      let _route = this.$route;
      this.$store.commit('isHomeFirst', _route.name == 'home' && (_route.query.id == '0' || _route.query.id == undefined) ? true : false );
      this.asideActive = '/' + item.path;
      // 页面跳转是混动条回到顶部
      document.body.scrollTop = document.documentElement.scrollTop = 0;
      if(this.isScreenWidth) {
        this.$emit('menuChange', item);
      }
    }
  }
}
</script>

<style lang="scss">
.aside {
  display: inline-block;
  vertical-align: top;
  position: relative;
}
.brn-switch {
  position: absolute;
  right: -22px;
  top: 0;
  display: inline-block;
  padding: 1px;
  background-color: #ccc;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  z-index: 999;
}

.el-menu {
  border-right: none !important;
  background-color: transparent !important;
  i {
    color: #fff;
  }
}
.el-aside {
  overflow: initial;
}

.el-menu--horizontal .el-menu .el-menu-item {
  height: 42px;
  line-height: 42px;
}


.screen-width {

} 


</style>
<style lang="scss">
.aside {
  .el-menu-item, .el-submenu__title {
    padding: 0 26px;
    font-size: 18px !important;
  }
  .el-menu--horizontal > .el-submenu .el-submenu__title:hover {
    background-color: $themes_hover_color !important;
    color: #fff !important;
  }
  .el-icon-arrow-down {
    display: none;
  }
}
.el-menu--horizontal .el-menu-item:not(.is-disabled):hover, 
.el-menu--horizontal .el-menu-item:not(.is-disabled):focus {
  background-color: $themes_hover_color !important;
  color: #fff !important;
}

.el-menu--horizontal .el-menu {
  overflow: hidden;
  background-color: $themes_color_rgba !important;
  .el-menu-item {
    color: #fff !important;
  }
}
.el-menu--popup {
  min-width: 100px !important;
}

.screen-width {
  width: 100%;
  .el-menu {
    width: 100%;
  }
  .el-icon-arrow-down {
    display: inline-block;
    font-size: 16px;
    color: #fff;
  }
  .el-menu-item, .el-submenu__title,
  .el-submenu .el-menu-item {
    height: 40px;
    line-height: 40px;
    font-size: 14px !important;
    text-align: left;
    color: #fff !important;
  }
  .el-menu-item-group__title {
    padding: 0;
  }
  li {
    animation: liMoves 1s linear;
    opacity: 1;
    transform: translateY(0);
  }
}

.el-drawer__body {
  background-color: $themes_color;
}
</style>