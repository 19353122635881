/**
 * 菜单列表设置
 */
const menuList = [
  {
    name: '关于栢田',
    type: '0',
    id: '1',
    path: 'home',
    children: [
      {
        id: '11',
        name: '企业概况',
        type: '1',
        path: 'home',
        serviceId: '1',
      },
      {
        id: '12',
        name: '发展历程',
        type: '1',
        path: 'home',
        serviceId: '2',
      },
      {
        id: '13',
        name: '企业文化',
        type: '1',
        path: 'home',
        serviceId: '3',
      },
      {
        id: '14',
        name: '企业荣誉',
        type: '1',
        path: 'home',
        serviceId: '4',
      },
      {
        id: '15',
        name: '企业资质',
        type: '1',
        path: 'home',
        serviceId: '5',
      }
    ]
  },
  {
    name: '业务板块',
    type: '0',
    id: '2',
    path: 'business',
    children: [
      {
        id: '21',
        name: '维尔医疗技术有限公司',
        type: '1',
        path: 'businessDetail',
        serviceId: '0',
      },
      {
        id: '22',
        name: '医修技术服务（北京）有限公司',
        type: '1',
        path: 'businessDetail',
        serviceId: '1',
      },
      {
        id: '23',
        name: '云南健禾科技有限公司',
        type: '1',
        path: 'businessDetail',
        serviceId: '2',
      },
      {
        id: '24',
        name: '云南栢辰医疗器械有限公司',
        type: '1',
        path: 'businessDetail',
        serviceId: '3',
      }
    ]
  },
  {
    name: '核心产品',
    type: '0',
    id: '3',
    path: 'products',
    children: [
      {
        id: '31',
        name: '医润通',
        type: '1',
        path: 'productsDetail',
        serviceId: '0',
      },
      {
        id: '32',
        name: '医修库',
        type: '1',
        path: 'productsDetail',
        serviceId: '1',
      }
    ]
  },
  {
    name: '新闻资讯',
    type: '0',
    id: '4',
    path: 'information',
    children: [
      {
        id: '41',
        name: '企业新闻',
        type: '1',
        path: 'information',
        serviceId: '0',
      },
      {
        id: '42',
        name: '企业视频',
        type: '1',
        path: 'information',
        serviceId: '1',
      }
    ]
  },
  {
    name: '案例分享',
    type: '0',
    id: '5',
    path: 'case',
  },
  {
    name: '人才招聘',
    type: '0',
    id: '6',
    path: 'recruit',
    children: [
      {
        id: '61',
        name: '职位申请',
        type: '1',
        path: 'recruit',
        serviceId: '0',
      },
      {
        id: '62',
        name: '培训发展',
        type: '1',
        path: 'recruit',
        serviceId: '1',
      },
      {
        id: '63',
        name: '工作环境',
        type: '1',
        path: 'recruit',
        serviceId: '2',
      },
      {
        id: '64',
        name: '团队建设',
        type: '1',
        path: 'recruit',
        serviceId: '3',
      },
      {
        id: '65',
        name: '栢田生活',
        type: '1',
        path: 'recruit',
        serviceId: '4',
      }
    ]
  },
  {
    name: '联系我们',
    type: '0',
    id: '7',
    path: 'contactus',
  }
]

export{ menuList };