<template>
  <div class="bt-case" :style="'min-height:' + height + 'px'">
    <div class="bt-main-container">
      <div class="bt-title">案例分享<span class="bt-subtitle">CASE  SHARING</span></div>
      <ul class="bt-case-list">
        <li v-for="(item, index) in caseList" :key="index"
          @click="caseChange(item)">
          <el-image
            class="bt-information-image"
            :src="item.image"
            fit="fill"></el-image>
          <div class="bt-case-context">
            <p class="bt-case-title">{{item.title}}</p>
            <p class='bt-case-subtitle bt-overflow3'>{{item.context}}</p>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { list } from './list.js';
export default {
  data() {
    return {
      height: window.innerHeight - 60,
      caseList: list,
    }
  },
  mounted() {
    
  },
  methods: {

    // 列表点击
    caseChange(item) {
      console.log(item);
      // this.$router.push({
      //   name: 'caseDetail',
      //   query: {
      //     id: item.id
      //   }
      // })
    }
    
    
  }
}
</script>

<style scoped lang="scss">
  .bt-case {
    // background-image: url('../../assets/images/product-bg.png');
    // background-repeat: no-repeat;
    // background-size: cover;
    background-color: #fff;
    padding-bottom: 60px;
  }

  .bt-case-list {
    margin-left: -15px;
    margin-right: -15px;
    display: flex;
    flex-wrap: wrap;
    li {
      position: relative;
      margin: 15px;
      width: calc(50% - 60px);
      height: 380px;
      border: 1px dashed #ccc;
      overflow: hidden;
      cursor: pointer;
      &:hover {
        .bt-case-context {
          background-color: $themes_color;
          color: #fff;
        }
        .bt-information-image {
          transform: scale(1.03);
        }
      }
    }
  }

  .bt-information-image {
    width: 100%;
    height: 100%;
    transition: all 0.4s;
  }

  .bt-case-context {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 20px;
    width: calc(100% - 40px);
    height: 80px;
    background-color: #fff;
    color: #666;
    transition: all 0.4s;
    p {
      margin: 0;
      text-indent: 2rem;
      font-size: 14px;
    }
  }

  .bt-case-title {
    text-indent: 0;
    margin-bottom: 6px;
    font-size: 18px;
  }


@media Screen and (max-width: $screen_width) {
  .bt-case-list {
    margin-left: 0;
    margin-right: 0;
    li {
      margin: 15px 0;
      width: 100%;
    }
    .bt-case-title {
      margin-bottom: 12px;
      font-size: 14px;
    }
    .bt-case-subtitle {
      font-size: 12px;
    }
  }

}

</style>