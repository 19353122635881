<template>
  <div class="bt-case-detail" :style="'min-height:' + height + 'px'">
    <div class="bt-case-detail-container">
      <div class="bt-title">案例分享<span class="bt-subtitle">CASE  SHARING</span></div>
      案例分享详情{{id}}
    </div>
  </div>
</template>
<script>
import { list } from './list.js'
export default {
  data() {
    return {
      id: '',
      info: {},
    }
  },
  watch: {
    $route: {
      handler: function(val) {
        this.id = val.query.id;
        this.getDetailData();
      }
    }
  },
  created() {
    this.id = this.$route.query.id;
    this.getDetailData();
  },
  methods: {
    // 获取数据
    getDetailData() {
      list.forEach(item => {
        if(this.id == item.id) {
          this.info = item;
        }
      })
    },
    
  }
}
</script>

<style scoped lang="scss">
  .bt-case-detail {
    // background-image: url('../../assets/images/product-bg.png');
    // background-repeat: no-repeat;
    // background-size: cover;
    background-color: #fff;
    padding-bottom: 60px;
  }
  .bt-case-detail-container {
    width: $min-width;
    margin: 0 auto;
  }

</style>