import Vue from 'vue'
import Vuex from 'vuex'
import {primaryColor} from '@/utils/baseText'

Vue.use(Vuex)

const store = new Vuex.Store({
    state:{
      //存放的键值对就是所要管理的状态
      //主题颜色  判断是否有缓存主题色，有取缓存中的，没有则取定义好的
      primaryColor: localStorage.getItem('primaryColor') ? localStorage.getItem('primaryColor') : primaryColor,  
      isHomeFirst: true,  //是否是首页第一页
    },
    mutations: {
      primaryColor: function(state, data) {
        state.primaryColor = data 
      },
      isHomeFirst: function(state, data) {
        state.isHomeFirst = data 
      },
    }
})

export default store