<template>
  <div class="td-screen" ref="screen" > 
    <div class="oprea" ref="screenOpera">
      <slot name="oprea"></slot>
    </div>
    <div class="search" ref="screenRight">
      <slot name="right"></slot>
    </div>
    <slot></slot>
  </div>

</template>

<script>
export default {
  name: 'td-screen',
  data() {
    return {
      // screenWidth: this.$refs.screen.clientWidth
    }
  },
  watch: {
    // screenWidth() {
    //   this.getLayoutScreen()
    // }
  },
  mounted() {
    // this.$nextTick(() => {
      this.getLayoutScreen()
      // 屏幕变化时监听宽度变化
      window.addEventListener('resize', () => {
        setTimeout(() => {
          this.getLayoutScreen()
        }, 500)
      })
    // })
  },
  
  methods: {
    // 计算右侧的布局宽度
    getLayoutScreen(){
      let screenWidth = this.$refs.screen.clientWidth
      let operaWidth = this.$refs.screenOpera.clientWidth
      let right = this.$refs.screenRight
      right.style.width = screenWidth - operaWidth - 36 + 'px'
      if(operaWidth > 0) {
        right.style.marginLeft = 30 + 'px'
      } else {
        right.style.marginLeft = 0 + 'px'
      }
    },

  }
}
</script>

<style lang="scss">
  .td-screen {
    position: relative;
    margin-bottom: 10px;
    display: flex;
    .oprea {
      flex: 1;
      margin-right: 100px;
      .el-button {
        margin-right: 10px;
      }
    }
    .search {
      flex: 1;
      text-align: right;
      .el-input, .el-select {
        width: 250px;
        margin-right: 20px;
      }
      .el-button {
        margin-right: 5px;
      }
    };
    .other {
      display: inline-block;
    }
  }
</style>