<!-- 
* @Author: yangtao  
* @Description: table组件
-->
<template>
  <div class="yt-table">
    <yt-screen-search :key="secreenSearchTimer">
      <template slot="oprea">
        <el-button type="warning" @click="add" v-if="isAdd">新增</el-button>
        <el-button type="danger" @click="deletes" v-if="isDelete"
          >删除</el-button
        >
        <slot name="operaItem"></slot>
      </template>
      <template slot="right">
        <slot name="searchItem"></slot>
        <el-input
          v-if="isInputSearch"
          v-model="query.name"
          :placeholder="searchPlaceholder"
          @keyup.enter.native="search"
          class="yt-table-input"
        >
          <!-- <template slot="append">
            <div class="input-append" @click="search">搜素</div>
          </template>-->
        </el-input>
        <div class="yt-table-search">
          <el-button v-if="isBtnSearch" type="primary" @click="search"
            >搜索</el-button
          >
          <el-button v-if="isBtnCancel" @click="cancel">重置</el-button>
        </div>
        <div class="yt-seaech-btn" style="display: inline-block">
          <slot name="searchBtn"></slot>
        </div>
      </template>
      <div v-if="isColumnSetting" class="column-setting">
        <el-dropdown>
          <i class="column-setting-icon el-icon-s-grid"></i>
          <el-dropdown-menu slot="dropdown" class="column-setting-dropdow">
            <el-checkbox-group v-model="columnSetting">
              <el-checkbox
                v-for="item in columnSettingItem"
                :label="item"
                :key="item"
                @change="checkboxChange(item)"
                >{{ item }}</el-checkbox
              >
            </el-checkbox-group>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </yt-screen-search>
    <div class="yt-table-other">
      <slot name="searchOther"></slot>
    </div>

    <el-table
      ref="yttable"
      v-loading="query.loading"
      :data="tableData"
      border
      :stripe="stripe"
      @select="tableSelect"
      @select-all="tableSelectAll"
      :default-sort="{ prop: 'effDate', order: 'descending' }"
      :tree="tree"
      :lazy="lazy"
      :load="loadChildren"
      :row-key="rowKey"
      :default-expand-all="defaultExpandAll"
      :expand-row-keys="expands"
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <!-- 多选 -->
      <el-table-column
        v-if="tableSelectType == 'selection'"
        type="selection"
        width="40"
        align="center"
        fixed="left"
        :selectable="selectable"
      ></el-table-column>

      <!-- 单选 -->
      <el-table-column
        v-if="tableSelectType == 'radio'"
        width="40"
        align="center"
        fixed="left"
      >
        <template slot-scope="scope">
          <el-radio
            class="yt-drop-radio"
            v-model="radio"
            :label="scope.row[rowKey]"
            @change.native="getCurrentRow(scope.row)"
            ><span v-show="false">占位符</span></el-radio
          >
        </template>
      </el-table-column>

      <el-table-column
        v-if="isIndex"
        type="index"
        width="40"
        fixed="left"
      ></el-table-column>
      <div v-for="(column, index) in columnItem" :key="index">
        <el-table-column
          v-if="column.show"
          sortable
          :prop="column.prop"
          :label="column.label"
          :width="column.width"
          :min-width="column.minWidth"
          :align="column.align"
          :formatter="column.formatter"
          :fixed="column.fixed"
          :show-overflow-tooltip="true"
        />
      </div>

      <!-- 表格中显示不同方式 -->
      <el-table-column
        v-if="other"
        sortable
        :prop="otherItem.prop"
        :label="otherItem.label"
        :width="otherItem.width"
        :min-width="otherItem.minWidth"
        :align="otherItem.align"
        :formatter="otherItem.formatter"
        :fixed="otherItem.fixed"
      >
        <template slot-scope="scope">
          <slot name="otherColumn" :scope="scope"></slot>
        </template>
      </el-table-column>

      <el-table-column
        v-if="isOperation"
        prop="operation"
        label="操作"
        :width="columnOperationWidth"
        align="center"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-link
            v-if="isLook"
            type="primary"
            @click="lookRow(scope.row)"
            :disabled="scope.row.lookDisabled"
            >查看</el-link
          >
          <el-link
            v-if="isEdit"
            type="warning"
            @click="editRow(scope.row)"
            :disabled="scope.row.ediytisabled"
            >编辑</el-link
          >
          <el-popconfirm
            v-if="isDeleteRow"
            :disabled="scope.row.deleteDisabled"
            title="确定要删除整行数据吗？"
            @onCancel="$message.info('取消删除')"
            @onConfirm="deleteRow(scope.row)"
          >
            <el-link
              slot="reference"
              type="danger"
              :disabled="scope.row.deleteDisabled"
              >删除</el-link
            >
          </el-popconfirm>

          <el-dropdown
            v-if="isMore"
            @command="commandDropdown($event, scope.row)"
          >
            <span class="el-dropdown-link yt-dropDown-icon">
              更多
              <i
                class="el-icon-arrow-down el-icon--right"
                style="margin-left: 0"
              ></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-for="(drop, dragIndex) in dropdownItem"
                :command="drop.code"
                :key="dragIndex"
                >{{ drop.name }}</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
          <slot name="columnItem" :scope="scope"></slot>
          <!-- <slot name="columnItem" :scope="scope"></slot> -->
        </template>
      </el-table-column>
    </el-table>
    <!-- page -->
    <el-pagination
      v-if="isPagination"
      @size-change="pageSizeChange"
      @current-change="pageCurrentChange"
      layout="total, sizes, prev, pager, next, jumper"
      :page-sizes="[5, 10, 15, 20, 40, 100]"
      :page-size="query.size"
      :current-page="query.current"
      :total="query.total"
    ></el-pagination>
  </div>
</template>

<script>
import { arrayToTree } from "@/utils/utils.js";

export default {
  name: "yt-table",
  components: {},
  props: {
    isFirstLoad: { type: Boolean, default: true },
    searchPlaceholder: { type: String, default: "请输入名称" },
    searchName: { type: String, default: "searchWord" },
    tableUrl: { type: String, default: "/sam/serviceInst/allServiceInst" },
    queryParam: Object,
    isAdd: { type: Boolean, default: true },
    isOperation: { type: Boolean, default: true },
    isLook: { type: Boolean, default: true },
    isEdit: { type: Boolean, default: true },
    isDelete: { type: Boolean, default: true },
    isDeleteRow: { type: Boolean, default: true },
    isColumnSetting: { type: Boolean, default: true },
    defaultExpandAll: { type: Boolean, default: false },
    tree: { type: Boolean, default: false },
    columnOperationWidth: { type: String, default: "220px" },
    other: { type: Boolean, default: false },
    isMore: { type: Boolean, default: false },
    isIndex: { type: Boolean, default: false },
    isInputSearch: { type: Boolean, default: true },
    isBtnSearch: { type: Boolean, default: true },
    isBtnCancel: { type: Boolean, default: true },
    pageSize: { type: Number, default: 15 },
    otherItem: Object,
    dropdownItem: {
      type: Array,
      default: function () {
        return ["更多"];
      },
    },
    columnItem: {
      type: Array,
      default: function () {
        return [
          {
            prop: "name",
            label: "平台名称",
            minWidth: "180",
          },
          {
            prop: "customerName",
            label: "客户名称",
            minWidth: "180",
          },
        ];
      },
    },
    selectable: { type: Function },
    tableSelectType: { type: String, default: "selection" },

    lazy: { type: Boolean, default: false },
    stripe: { type: Boolean, default: true },
    // load: Function,
    rowKey: { type: String, default: "serviceInstID" },
  },
  data() {
    return {
      query: {
        name: "",
        size: this.pageSize,
        current: 1,
        total: 0,
        loading: false,
      },

      tableData: [],

      selectArr: [],

      // 列设置
      columnSetting: [],
      columnSettingItem: [],

      isPagination: true, //是否显示分页
      expands: [],
      secreenSearchTimer: "",

      radio: "",
    };
  },
  mounted() {
    if (this.isFirstLoad) {
      this.getTableData();
    }
    this.secreenSearchTimer = new Date().getTime();
  },
  watch: {
    queryParam() {
      this.getTableData();
    },
    isAdd() {
      this.secreenSearchTimer = new Date().getTime();
    },
  },
  computed: {
    
  },
  methods: {
    //el-table的操作方法
    selectRow(rowIDList, flag) {
      let selectRows = [];
      rowIDList.forEach((rowID) => {
        this.tableData.forEach((data) => {
          if (rowID == data[this.rowKey]) {
            selectRows.push(data);
          }
        });
      });

      selectRows.forEach((row) => {
        this.$refs.yttable.toggleRowSelection(row, flag);
      });
    },
    // 获取表格数据
    getTableData() {
      this.query.loading = true;
      if (this.tree) {
        this.query.size = 99999999;
        this.isPagination = false;
      }
      const query = {
        params: {
          limit: this.query.size,
          page: this.query.current,
          [this.searchName]: this.query.name,
          ...this.queryParam,
        },
      };
      this.$emit("query", query.params); // 添加查询条件
      this.$request("GET",this.tableUrl, query).then((res) => {
        this.query.total = res.total;
        this.query.loading = false;

        if (res.length) {
          //判断是否有分页信息，如果有，则直接显示树形表格
          this.isPagination = false;
          this.tableData = arrayToTree(res, this.rowKey);
          this.expands.push(this.tableData[1][this.rowKey]);
        } else {
          if (this.tree) {
            //是否显示整棵树信息
            this.tableData = arrayToTree(res.list, this.rowKey);
          } else {
            this.tableData = res.list;
          }
        }

        this.$emit("getTableData", this.tableData, res);
      });

      // 循环column使其所有的show都为true
      this.columnItem.forEach((item) => {
        item.show = true;
      });

      // 获取列设置数据
      this.columnSettingItem = this.columnItem.map((item) => {
        return item.label;
      });

      // 默认全部选中
      this.columnSetting = this.columnItem.map((item) => {
        return item.label;
      });

      // 初始化时刷新搜索框
      // console.log('sss',this.$refs.screenSearch.getLayoutScreen)

      // 单选
    },

    // 树形结构懒加载
    loadChildren(tree, treeNode, resolve) {
      const query = {
        params: {
          parentID: tree[this.rowKey],
          limit: 999999,
          serviceInstID: JSON.parse(localStorage.getItem("serviceInst"))
            .serviceInstID,
        },
      };
      this.$request("GET",this.tableUrl, query).then((data) => {
        resolve(data.list);
        console.log(this.tableData);
      });
    },

    // 表格列设置多选改变时
    checkboxChange(val) {
      this.columnItem.forEach((item) => {
        if (item.label == val) {
          item.show = !item.show;
        }
      });
    },

    // 单选项点击
    getCurrentRow(row) {
      // console.log(this.tableData)
      // console.log(row[this.rowKey])
      this.$emit("radioChange", row);
    },

    // 新增
    add() {
      this.$emit("add");
    },

    // 删除多项
    deletes() {
      this.$confirm("此操作将永久删除该条数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (this.selectArr < 1) {
            this.$message.error("请选择需要删除的数据");
            return;
          }
          this.$emit("deletes", this.selectArr);
          setTimeout(() => {
            this.selectArr = [];
            this.getTableData();
          }, 500);
        })
        .catch(() => {
          this.$message.info("已取消删除");
        });
    },

    // 点击复选框
    tableSelect(row) {
      this.selectArr = row;
      this.$emit("tableSelect", row);
    },

    // 点击复选框全选
    tableSelectAll(row) {
      this.selectArr = row;
      this.$emit("tableSelectAll", row);
    },

    // 每页条数改变时
    pageSizeChange(val) {
      this.query.size = val;
      this.getTableData();
    },

    // 当前页改变时
    pageCurrentChange(val) {
      this.query.current = val;
      this.getTableData();
    },

    // 查看
    lookRow(row) {
      this.$emit("lookRow", row);
    },

    // 编辑
    editRow(row) {
      this.$emit("editRow", row);
    },

    //删除
    deleteRow(row) {
      this.$emit("deleteRow", row);
      setTimeout(() => {
        this.getTableData();
      }, 500);
    },

    // 查询
    search() {
      // this.query.size = 99999;
      this.query.current = 1;
      this.$emit("search");
      this.getTableData();
    },

    // 重置
    cancel() {
      this.query.size = 15;
      this.query.current = 1;
      this.query.name = "";
      this.$emit("cancel");
      this.$nextTick(() => {
        this.getTableData();
      });
      this.$emit("reset");
    },

    // 更多下拉点击
    commandDropdown(command, row) {
      this.$emit("commandDropdown", command, row);
    },
  },
};
</script>

<style scoped lang="scss">
.yt-table {
  position: relative;
  padding: 15px;
  background-color: #fff;
}
.column-setting {
  position: absolute;
  right: 20px;
  top: 62px;
  z-index: 9;
  .column-setting-icon {
    font-size: 24px;
    color: #888;
    cursor: pointer;
    &:hover {
      // color: $themes_color;
    }
  }
}
.yt-table-search {
  display: inline-block;
  width: 160px;
}
.yt-dropDown-icon {
  // color: $edit_color;
  cursor: pointer;
}
</style>
<style lang="scss">
.column-setting-dropdow {
  padding: 10px;
  .el-checkbox {
    display: block;
    padding: 6px 0;
  }
}
.yt-table-input {
  .el-input-group__append {
    padding: 0 !important;
    height: 38px;
    line-height: 38px;
    border-radius: 0 4px 4px 0;
    .input-append {
      padding: 0 20px;
      // background-color: $themes_color;
      color: #fff;
    }
  }
}
.yt-table {
  .yt-screen {
    text-align: right;
    .oprea {
      margin-right: 0;
    }
    .search {
      .el-input {
        margin-right: 15px;
      }
      .el-cascader {
        @extend .el-input;
        width: calc(20% - 32px - 15px) !important;
        .el-input {
          width: 100%;
        }
      }
      .el-select {
        @extend .el-input;
        .el-input {
          width: 100%;
        }
      }
      .yt-select-page {
        @extend .el-input;
      }
    }
  }
  .el-table {
    ::-webkit-scrollbar {
      transition: all 0.5s;
      width: 0px;
      height: 4px;
    }
    ::-webkit-scrollbar-track-piece {
      background: transparent;
    }
    ::-webkit-scrollbar-thumb {
      background: transparent;
      border-radius: 20px;
    }

    &:hover {
      ::-webkit-scrollbar {
        width: 0px;
        height: 4px;
      }
      ::-webkit-scrollbar-track-piece {
        background: #eee;
      }
      ::-webkit-scrollbar-thumb {
        background: #ccc;
        border-radius: 20px;
      }
    }
  }
}
.yt-drop-radio {
  transform: scale(0.8);
}
</style>