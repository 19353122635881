<template>
  <div class="bt-recruit" :style="'min-height:' + height + 'px'">
    <div class="bt-main-container">
      <div class="bt-title">人才招聘<span class="bt-subtitle">TALENT  RECRUITMENT</span></div>
      <ul class="bt-recruit-list">
        <li v-for="(item, index) in recruitList" :key="index">
          <el-image
            class="bt-recruit-image"
            :src="item.image"
            fit="fill"></el-image>
            <div class="bt-recruit-title">{{item.title}}</div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { list } from './list.js';
export default {
  data() {
    return {
      height: window.innerHeight - 60,
      recruitList: list,
    }
  },
  mounted() {
    
  },
  methods: {
    
    
  }
}
</script>

<style scoped lang="scss">
  .bt-recruit {
    // background-image: url('../../assets/images/product-bg.png');
    // background-repeat: no-repeat;
    // background-size: cover;
    background-color: #fff;
    padding-bottom: 60px;
  }

  .bt-recruit-list {
    display: flex;
    flex-wrap: wrap;
    li {
      overflow: hidden;
      width: 33.333%;
      text-align: center;
      cursor: pointer;
      transition: all 0.4s;
      &:hover {
        .bt-recruit-image {
          transform: scale(1.03);
        }
        .bt-recruit-title {
          font-weight: bolder;
          color: $themes_color;
        }
      }
    }
    li:nth-child(1),
    li:nth-child(2) {
      width: 50%;
      .bt-recruit-image {
        height: 320px;
      }
    }
  }

  .bt-recruit-image {
    width: 100%;
    height: 260px;
    transition: all 0.4s;
  }
  .bt-recruit-title {
    margin-bottom: 20px;
    height: 48px;
    line-height: 48px;
    font-size: 30px;
    color: #666;
    transition: all 0.4s;
  }


@media Screen and (max-width: $screen_width) {
  .bt-recruit-list {
    li {
      width: 100%;
    }
    li:nth-child(1),
    li:nth-child(2) {
      width: 100%;
      .bt-recruit-image {
        height: 200px;
      }
    }
  }
  .bt-recruit-title {
    font-size: 18px;
  }

}


</style>