<!-- 
* @Author: yangtao  
* @Description: 弹框表格组件
    v-bind="$attrs" 
    v-on="$listeners"
-->

<template>
  <el-dialog
    class="yt-dialog-form"
    :title="title"
    :visible.sync="visibleDialog"
    @close="closed"
    :width="formColumn.length >= 10 ? '1100px' : '600px'"
  >
    <el-form
      :class="{'yt-form-overflow': formColumn.length >= 10}"
      ref="form"
      :model="form"
      :label-width="labelWidth"
      :label-position="labelPosition"
      show-message
    >
      <el-form-item
        v-for="(item, index) in formColumn"
        :key="index"
        :label="item.label"
        :prop="item.key"
        :rules="item.rules"
        :required="item.required"
        v-show="!item.isHidden"
      >
        <!-- 基础输入框 -->
        <el-input
          v-if="item.type == 'input' && !isReadOnly"
          v-model="form[item.key]"
          :type="item.inputType || 'String'"
          :disabled="item.disabled"
          @change="formColumnChange($event, item)"
        ></el-input>
        <span v-if="item.type == 'input' && isReadOnly">{{
          form[item.key]
        }}</span>

        <!-- 单选框 -->
        <el-radio-group
          v-if="item.type == 'radio' && !isReadOnly"
          v-model="form[item.key]"
          @change="formColumnChange($event, item)"
        >
          <el-radio
            v-for="child in item.children"
            :label="child.value"
            :key="child.value"
            :disabled="child.disabled"
            >{{ child.label }}</el-radio
          >
        </el-radio-group>
        <span v-if="item.type == 'radio' && isReadOnly">{{
          form[item.key] | readOnlyRadio(item)
        }}</span>

        <!-- 多选框 -->
        <el-checkbox-group
          v-if="item.type == 'checkbox' && !isReadOnly"
          v-model="form[item.key]"
          @change="formColumnChange($event, item)"
        >
          <el-checkbox
            v-for="child in item.children"
            :label="child.value"
            :key="child.value"
            :disabled="child.disabled"
            >{{ child.label }}</el-checkbox
          >
        </el-checkbox-group>
        <span v-if="item.type == 'checkbox' && isReadOnly">{{
          form[item.key] | readOnlyCheckbox(item)
        }}</span>

        <!-- 选择框 -->
        <el-select
          v-if="item.type == 'select' && !isReadOnly"
          clearable
          :multiple="item.multiple || false"
          v-model="form[item.key]"
          :placeholder="item.placeholder || '请选择'"
          style="width: 100%"
          @change="formColumnChange($event, item)"
        >
          <el-option
            v-for="child in item.children"
            :key="child.value"
            :label="child.label"
            :disabled="child.disabled"
            :value="child.value"
          >
          </el-option>
        </el-select>
        <span v-if="item.type == 'select' && isReadOnly">{{
          form[item.key] | readOnlySelect(item)
        }}</span>

        <!-- 级联选择 -->
        <!-- multiple 多选 -->
        <!-- checkStrictly 是否严格的遵守父子节点不互相关联-->
        <el-cascader
          :disabled="isReadOnly"
          style="width: 100%"
          v-if="item.type == 'cascader'"
          clearable
          filterable
          v-model="form[item.key]"
          @change="formColumnChange($event, item)"
          :options="item.children"
          :props="{
            expandTrigger: 'hover',
            multiple: item.multiple,
            checkStrictly: item.checkStrictly,
          }"
        >
        </el-cascader>

        <!-- 开关 -->
        <el-switch
          :disabled="isReadOnly"
          v-if="item.type == 'switch'"
          v-model="form[item.key]"
          active-text="开"
          inactive-text="关"
          @change="formColumnChange($event, item)"
        >
        </el-switch>

        <!-- 滑块 -->
        <el-slider
          :disabled="isReadOnly"
          v-if="item.type == 'slider'"
          v-model="form[item.key]"
          @change="formColumnChange($event, item)"
        ></el-slider>

        <!-- 日期选择器 时间戳显示-->
        <el-date-picker
          style="width: 100%"
          clearable
          format="yyyy-MM-dd"
          v-if="item.type == 'date-picker' && !isReadOnly"
          v-model="form[item.key]"
          type="date"
          value-format="timestamp"
          placeholder="选择日期"
          @change="formColumnChange($event, item)"
        >
        </el-date-picker>
        <span v-if="item.type == 'date-picker' && isReadOnly">{{
          form[item.key]
        }}</span>
      </el-form-item>

      <!-- 其它插槽显示 -->
      <slot></slot>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="cancel">取 消</el-button>
      <el-button type="primary" @click="confirm">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: {
    // 标题
    title: {
      type: String,
      default: "提示",
    },

    // label-width
    labelWidth: {
      type: String,
      defalut: "100px",
    },

    // 是否显示弹框
    visible: {
      type: Boolean,
      default: false,
    },

    // 是否显示弹框
    isReadOnly: {
      type: Boolean,
      default: false,
    },

    /**
     * 表单格式 column
     * label 表单lable
     * type  表单类型  impute-文本输入 radio-单选 checkbox-多选 select-选择框
     *               cascader-级联选择 switch-开关 slide-滑块 date-picker-日期选择器
     * inputType 文本输入框类型  string（默认） number
     * key 表单的key值
     * isHidden 是否隐藏
     * children 单选、多选、级联等下拉框数据
     * multiple 下拉框、级联选择是否多选
     * required 是否必填
     */
    formColumn: {
      type: Array,
      default: () => {
        return [
          {
            label: "文本输入框",
            type: "input",
            key: "textInput",
            rules: [
              { required: true, message: "文本输入框必填", trigger: "blur" },
            ],
          },
          {
            label: "数字输入框",
            type: "input",
            inputType: "number",
            key: "numberInput",
          },
          {
            label: "单选框",
            type: "radio",
            key: "radio",
            children: [
              { label: "单选框0", value: '0' },
              { label: "单选框1", value: '1' },
              { label: "单选框2", value: '2', disabled: true },
            ],
          },
          {
            label: "多选框",
            type: "checkbox",
            key: "checkbox",
            children: [
              { label: "多选框0", value: '0' },
              { label: "多选框1", value: '1' },
              { label: "多选框2", value: '2', disabled: true },
            ],
          },
          {
            label: "选择框单选",
            type: "select",
            key: "select1",
            children: [
              { label: "选择框0", value: '0' },
              { label: "选择框1", value: '1' },
              { label: "选择框1", value: '2' },
              { label: "选择框2", value: '3', disabled: true },
            ],
            rules: [
              { required: true, message: "选择框单选必填", trigger: "change" },
            ],
          },
          {
            label: "选择框多选",
            type: "select",
            key: "select2",
            multiple: true,
            children: [
              { label: "选择框0", value: '0' },
              { label: "选择框1", value: '1' },
              { label: "选择框1", value: '2' },
              { label: "选择框2", value: '3', disabled: true },
            ],
          },
          {
            label: "级联选择",
            type: "cascader",
            key: "cascader",
            multiple: true,
            checkStrictly: true,
            children: [
              {
                label: "级联选择0",
                value: '0',
                children: [
                  { label: "级联选择11", value: '11', children: [
                    {label: '级联选择111', value: '111'},
                    {label: '级联选择222', value: '222'},
                  ] },
                  { label: "级联选择12", value: '12' },
                ],
              },
              { label: "级联选择1", value: '1' },
              { label: "级联选择2", value: '2' },
              { label: "级联选择3", value: '3', disabled: true },
            ],
          },
          { label: "开关", type: "switch", key: "switch" },
          { label: "滑块", type: "slider", key: "slider" },
          { label: "日期选择器", type: "date-picker", key: "datePicker" },
        ];
      },
    },

    // 表单数据
    form: {
      type: Object,
      default: function () {
        return {
          textInput: "文本输入框",
          numberInput: 222222,
          radio: '0',
          checkbox: ['0', '1'],
          select1: '0',
          select2: ['0', '1'],
          cascader: ['0', '11', '111'], 
          switch: true,
          slider: 40,
          datePicker: 1632412800000,
        };
      },
    },
  },
  data() {
    return {
      visibleDialog: this.visible,
      labelPosition: "right",
    };
  },
  watch: {
    visible(val) {
      this.visibleDialog = val;
    },
  },

  mounted() {
  },

  // 循环单选多选框中的数据，显示只读label
  filters: {
    // 单选框
    readOnlyRadio: (value, item) => {
      let label = '';
      item.children.forEach(child => {
        if (value == child.value) {
          label = child.label;
        }
      })
      return label;
    },
    // 多选框
    readOnlyCheckbox(value, item) {
      let labelArr = [];
      item.children.forEach(child => {
        value.forEach(val => {
          if(val == child.value) {
            labelArr.push(child.label);
          }
        })
      })
      // 将数组转成字符串join
      // 将字符串转数组 split
      return labelArr.join(',');
    },

    // 选择框
    readOnlySelect(value, item) {
      if(item.multiple) {  //多选
        let labelArr = [];
        item.children.forEach(child => {
          value.forEach(val => {
            if(val == child.value) {
              labelArr.push(child.label);
            }
          })
        })
        return labelArr.join(',');
      } else {
        let label = '';
        item.children.forEach(child => {
          if(value == child.value) {
            label = child.label;
          }
        })
        return label;
      }
    },
    
  },
  computed: {
  },

  methods: {

    // 确认
    confirm() {
      console.log(this.form);
      // this.$emit("close");
      // this.$emit("update:visible", this.visibleDialog);
      this.$emit("confirm", this.form);
    },

    // 取消按钮
    cancel() {
      this.$emit("close");
    },

    // 关闭弹框
    closed() {
      this.$emit("close");
    },

    // 输入框事件
    formColumnChange(data, item) {
      this.$emit("change", data, item);
    },
  },
};
</script>

<style lang="scss">
.yt-dialog-form {
  // 只读情况下级联选择样式调整
  .el-cascader.is-disabled {
    .el-input.is-disabled .el-input__inner {
      border: none;
      background-color: transparent;
    }
    .el-input__suffix {
      display: none;
    }
    .el-cascader__tags .el-tag {
      margin-left: 0;
      padding-left: 0;
      background-color: transparent;
      text-align: left;
      color: #333;
    }
  }
  .yt-form-overflow {
    display: flex;
    flex-wrap: wrap;
    .el-form-item {
      flex: 1;
      min-width: 50%;
    }
  }
}
</style>