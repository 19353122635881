<template>
  <div class="bt-information-detail" :style="'min-height:' + height + 'px'">
    <div class="bt-main-container">
      <div class="bt-inforamtion-title">{{info.title}}</div>
      <p class="bt-information-date">发布时间 {{info.date}}</p>
      <div class="bt-information-context" v-html="info.context"></div>
    </div>
  </div>
</template>
<script>
import { list } from './list.js'
export default {
  data() {
    return {
      height: window.innerHeight - 60,
      id: '',
      info: {},
    }
  },
  watch: {
    $route: {
      handler: function(val) {
        this.id = val.query.id;
        this.getDetailData();
      }
    }
  },
  created() {
    this.id = this.$route.query.id;
    this.getDetailData();
  },
  methods: {
    // 获取数据
    getDetailData() {
      list.forEach(item => {
        if(this.id == item.id) {
          this.info = item;
        }
      })
    },
    
  }
}
</script>

<style scoped lang="scss">
  .bt-information-detail {
    // background-image: url('../../assets/images/product-bg.png');
    // background-repeat: no-repeat;
    // background-size: cover;
    background-color: #fff;
    padding-bottom: 60px;
  }

  .bt-inforamtion-title {
    padding-top: 50px;
    text-align: center;
    font-size: 40px;
    color: $themes_color;
  }
  .bt-information-date {
    padding-bottom: 10px;
    border-bottom: 1px solid #666;
    text-align: center;
    font-size: 16px;
    color: #666
  }
  .bt-information-context {
    font-size: 19.8px !important;
    line-height: 36px !important;
    color: #666 !important;
  }

@media Screen and (max-width: $screen_width) {
  .bt-inforamtion-title {
    font-size: 24px;
  }
  img {
    width: 100%;
  }

}



</style>


<style lang="scss">
@media Screen and (max-width: $screen_width) {
  .bt-information-detail{
    img {
      height: 250px !important;
      width: 100% !important;
    }
    * {
      font-size: 12px !important;
    }
  }

}
</style>