<template>
  <div class="home">
    加入我们
  </div>
</template>
<script>
export default {
  data() {
    return {
      
    }
  },
  mounted() {
    
  },
  methods: {
    
  }
}
</script>

<style scoped lang="scss">

</style>