<template>
  <div class="bt-video">
    <div class="bt-main-container">
      <div class="bt-title">企业视频<span class="bt-subtitle">ENTERPRISE  VIDEO</span></div>
      <ul class="bt-video-list">
        <li v-for="(item, index) in videoList" :key="index">
          <video-player
                class="bt-video-player"
                ref="videoPlayer"
                :playsinline="true"
                :options="item.playerOptions"
            ></video-player>
          <p style="color: #666;">{{item.name}}</p>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { videoList } from './list.js';
export default {
  data() {
    return {
      videoList: videoList,
    }
  },
  created() {
    this.videoList = videoList.map(item => {
      return {
        name: item.name,
        playerOptions: {
          playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
          autoplay: false, //如果true,浏览器准备好时开始回放。
          muted: false, // 默认情况下将会消除任何音频。
          loop: false, // 导致视频一结束就重新开始。
          preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
          language: 'zh-CN',
          aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
          fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
          sources: [{
            type: "",
            src: item.src//url地址
            // src: "" //url地址
          }],
          poster: item.poster, //你的封面地址
          // width: document.documentElement.clientWidth,
          notSupportedMessage: '此视频暂无法播放，请稍后再试', //允许覆盖Video.js无法播放媒体源时显示的默认信息。
          controlBar: {
            timeDivider: true,
            durationDisplay: true,
            remainingTimeDisplay: false,
            fullscreenToggle: true  //全屏按钮
          }
        }
      }
    })
  },
  methods: {
    
    
  }
}
</script>

<style scoped lang="scss">
  .bt-video {
    // background-image: url('../../assets/images/product-bg.png');
    // background-repeat: no-repeat;
    // background-size: cov er;
    overflow: auto;
    background-color: #fff;
    padding-bottom: 60px;
  }
  .bt-video-list {
    margin-top: 200px;
    display: flex;
    flex-wrap: wrap;
    li {
      padding: 20px;
      width: calc(50% - 40px);
      text-align: center;
    }
  }
  .bt-video-player {
    width: 550px;
  }
  

@media Screen and (max-width: $screen_width) {
  .bt-video-list {
    margin-top: 0px;
    margin-bottom: -50px;
    li {
      width: calc(100% - 40px);
    }
  }
  .bt-video-player {
    width: 100%;
  }

}


</style>
<style lang="scss">
  // 视频播放
  .video-js .vjs-big-play-button {
    top: calc(50% - 25px) !important;
    left: calc(50% - 25px) !important;
    width: 50px !important;
    height: 50px !important;
    border-radius: 50px !important;
  }

  .vjs-poster {
    background-size: cover !important;
  }
</style>