export const list = [
  {
    id: '0',
    image: 'https://image.baitianjituan.com/recruit0.png',
    title: '社会招聘',
  },
  {
    id: '1',
    image: 'https://image.baitianjituan.com/recruit1.png',
    title: '校园招聘',
  },
  {
    id: '2',
    image: 'https://image.baitianjituan.com/recruit5.png',
    title: '工作环境',
  },
  {
    id: '3',
    image: 'https://image.baitianjituan.com/recruit3.png',
    title: '团队建设',
  },
  {
    id: '4',
    image: 'https://image.baitianjituan.com/recruit4.png',
    title: '栢田生活',
  },
]