<template>
  <div class="bt-business-detail" :style="'min-height:' + height + 'px'">
    <div class="bt-main-container">
      <div class="bt-title">业务板块<span class="bt-subtitle">BUSINESS SEGMENT</span></div>
        <div class="bt-container">
          <el-image
            class="bt-business-image"
            :src="require('@/assets/images/' + info.imageName+ '.jpg')"
            fit="fill"></el-image>
            <div class="bt-context">
              <div class="bt-business-title">{{info.subName}}</div>
              <div class="bt-business-title">{{info.name}}</div>
              <div class="bt-text" :style="'height:' + textHeight + 'px'" v-html="info.context"></div>
              <template v-if="info.endoscope">
                <div class="bt-more" :class="{'bt-more-ok': isMore}" @click="moreChange">
                  <i class="el-icon-d-arrow-left"></i>
                </div>
                <div class="bt-operation">
                  <div class="bt-operation-item"
                    :class="{'bt-operation-item-active': operationActive == index}"
                    v-for="(item, index) in operationsList" :key="index"
                    @click="operationChange(item, index)">
                    <el-button>{{item.name}}</el-button>
                  </div>
                </div>
                <div v-if="isNature" style="transition: all 0.4s;"> 
                  <div style="color:#666;text-indent:2rem;">{{nature.context}}</div>
                  <div class="bt-business-title" style="margin-top: 20px;">经营范围</div>
                  <ul class="nature-list">
                    <li :style="'width:' + (nature.list.length <= 3 ? 'calc(100% - 40px)' : 'calc(50% - 40px)')" 
                      v-for="(item, index) in nature.list" :key="index">
                      <div v-html="item.text"></div>
                    </li>
                  </ul>
                  <div v-if="nature.product" class="bt-business-title" style="margin-top: 20px;">维修项目</div>
                  <ul v-if="nature.product" class="nature-list nature-product">
                    <li v-for="(item, index) in nature.product" :key="index">
                      <div v-html="item.text"></div>
                    </li>
                  </ul>
                </div>
              </template>
            </div>
        </div>
    </div>
  </div>
</template>
<script>
import { list } from './list.js'
export default {
  data() {
    return {
      height: window.innerHeight - 60,
      id: '',
      info: {},

      textHeight: 520,
      isMore: false, //是否显示更多
      isNature: false, //是否显示经营范围

      operationsList: [
        {name: '内镜维修中心', id: "endoscope"},
        {name: '影像维修中心', id: "image"},
        {name: '彩超维修中心', id: "ultrasound"},
      ],
      nature: {},
      operationActive: null,  //操作选中
    }
  },
  watch: {
    $route: {
      handler: function(val) {
        this.id = val.query.id;
        this.isMore = true;
        this.getDetailData();
      }
    }
  },
  created() {
    this.id = this.$route.query.id;
    this.getDetailData();
  },
  methods: {
    // 获取数据
    getDetailData() {
      list.forEach(item => {
        if(this.id == item.id) {
          this.info = item;
        }
      })
    },

    // 点击加载更多
    moreChange() {
      this.isMore ? this.textHeight = 520 : this.textHeight = 0;
      this.isMore = !this.isMore;
      if(this.isMore == false) {
        this.isNature = false;
        this.operationActive = null;
      }
    },

    // 操作点击
    operationChange(item, index) {
      this.operationActive = index;
      this.textHeight = 0;
      setTimeout(() => {
        this.isNature = true;
      }, 600)
      this.isMore = true;
      switch (item.id) {
        case 'endoscope':
          this.nature = this.info.endoscope;
          break;
        case 'image':
          this.nature = this.info.image;
          break;
        case 'ultrasound':
          this.nature = this.info.ultrasound;
          break;
      }
    }

  }
}
</script>

<style scoped lang="scss">
  .bt-business-detail {
    background-image: url('../../assets/images/business-bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom: 60px;
  }

  .bt-container {
    display: flex;
    .bt-business-image {
      margin-right: 80px;
      width: 500px;
      height: 75vh;
    }
    .bt-context {
      flex: 1;
    }
  }
  
  .bt-business-title {
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: bolder;
    color: $themes_color;
  }

  .bt-operation {
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    .bt-operation-item {
      flex: 1;
    }
    .el-button {
      background-color: transparent !important;
      border: 1px solid #707070;
      &:hover {
        background-color: $themes_color !important;
        border: 1px solid $themes_color;
        color: #fff;
      }
    }
    .bt-operation-item:nth-child(2) {
      text-align: center;
    }
    .bt-operation-item:nth-child(3) {
      text-align: right;
    }
    .bt-operation-item-active {
      .el-button {
        background-color: $themes_color !important;
        border: 1px solid $themes_color;
        color: #fff;
        transition: all 2s;
        animation: brnMove 0.4s linear;
        animation-fill-mode: forwards;
        width: 120px;
      }
    }
  }
    
  @keyframes brnMove {
    0% {
        width: 120px;
    }
    100% {
        width: 100%;
    }
  }

  #app {
    li {
      animation-delay: 0.5s;
    }
  }
  
  .bt-text {
    line-height: 20px !important;
    overflow: auto !important;
    color: #666 !important;
    transition: all 0.4s !important;
  }

  .nature-list {
    display: flex;
    flex-wrap: wrap;
    transition: all 0.4s;
    li {
      margin-bottom: 10px;
      padding-left: 20px;
      width: calc(50% - 40px);
      position: relative;
      color: #666;
      &::before {
        content: '';
        position: absolute;
        top: 6px;
        left: 0;
        display: inline-block;
        width: 10px;
        height: 10px;
        border-radius: 10px;
        background-color: $themes_color;  
      }
    }
    li:nth-child(2n - 1) {
      padding-right: 20px;
    }
    li:nth-child(2n) {
      padding-left: 20px;
    }
  }
  .nature-product {
    li {
      width: calc(100% - 40px);
    }
  }

  .bt-more {
    text-align: center;
    cursor: pointer;
    i {
      font-size: 22px;
      color: $themes_color;
      transform: rotateZ(270deg);
    }
  }

  .bt-more-ok {
    i {
      transform: rotateZ(90deg);
    }
  }


@media Screen and (max-width: $screen_width) {
  .bt-container {
    display: block;
  }
  .bt-container .bt-business-image {
    margin-right: 0;
    width: 100%;
  }
  .bt-context {
    margin-top: 20px;
  }
  .bt-business-title {
    font-size: 16px;
  }
  .bt-text {
    margin-top: 20px;
    height: auto !important;
    * {
      font-size: 12px !important;
    }
  }
  .bt-operation .bt-operation-item {
    width: 30%;
    padding: 0 1.33%;
    .el-button {
      padding: 10px 0;
      width: 100%;
      font-size: 12px;
    }
  }
}



</style>
<style lang="scss">
@media Screen and (max-width: $screen_width) {
  .bt-business-detail{
    .bt-text {
      * {
        font-size: 11.8px !important;
      }
    }
  }
}
</style>