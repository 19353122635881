import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
// 图表
// import echarts from 'echarts'
// Vue.prototype.$echarts = echarts

// 图表基础数据
// import {echartsOptions} from './utils/echarts'
// Vue.prototype.$echartsOptions = echartsOptions

// element
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './style/element-variables.scss'
Vue.use(ElementUI);

//封装的请求方法
import {get, post, del, put} from './request/request.js'; 
Vue.prototype.$get = get
Vue.prototype.$post = post
Vue.prototype.$del = del
Vue.prototype.$put = put

//轻量级的cookie this.$cookie.set('key', 'value') this.$cookie.get('key')
import jsCookie from 'js-cookie'  
Vue.prototype.$cookie = jsCookie


// 注册组件后即可使用
// Vue.component('v-chart', ECharts)

import YtTable from '@/components/table' // 表格组件封装
import YtScreenSearch from '@/components/screen-search' // 表格组件封装
import YtDialogForm from '@/components/dialog-form' // 表格组件封装
Vue.use(YtTable);
Vue.use(YtScreenSearch);
Vue.use(YtDialogForm);

import vueAwesomeSwiper from 'vue-awesome-swiper'
Vue.use(vueAwesomeSwiper);

import VideoPlayer from 'vue-video-player'
require('video.js/dist/video-js.css')
require('vue-video-player/src/custom-theme.css')
Vue.use(VideoPlayer)

Vue.config.productionTip = false

new Vue({
  router, 
  store,
  render: h => h(App),
}).$mount('#app')
