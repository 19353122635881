<template>
  <div class="bt-information" :style="'min-height:' + height + 'px'">
    <div class="bt-main-container">
      <div class="bt-title">新闻资讯<span class="bt-subtitle">NEWS  INFORMATION</span></div>
      <ul class="bt-information-list">
        <li v-for="(item, index) in informationList" :key="index" @click="informationChange(item)">
          <el-image
            class="bt-information-image"
            :src="item.image"
            fit="fill"></el-image>
            <div class="bt-information-text">
              <div class="bt-information-text-container">
                <h3>{{item.title}}</h3>
                <p class="bt-overflow2">{{item.subtitle}}</p>
                <el-button class="bt-information-btn">阅读全文<i class="el-icon-d-arrow-right"></i></el-button>
              </div>
            </div>
        </li>
      </ul>
      <div style="margin-top: 20px;text-align:right">
        <el-button type="primary" @click="moreClick">查看更多<i class="el-icon-d-arrow-right"></i></el-button>
      </div>
    </div>
  </div>
</template>
<script>
import { list } from './list.js';
export default {
  data() {
    return {
      height: window.innerHeight - 60,
      informationList: list.slice(0, 3),
    }
  },
  watch: {
    $route: {
      handler: function(val) {
        let id = val.query.id;
        if(id == '1') {
          this.$router.push({
            name: 'video',
          })
        }
      }
    }
  },
  created() {
    let id = this.$route.query.id;
    if(id == '1') {
      this.$router.push({
        name: 'video',
      })
    }
    
  },
  methods: {
    // 列表点击
    informationChange(item) {
      // 点击时滚动条先回到顶部
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      const timeTop = setInterval(() => {
        document.body.scrollTop = document.documentElement.scrollTop = top -= 50;
        if (top <= 0) {
          clearInterval(timeTop);
        }
      }, 10);
      this.$router.push({
        name: 'informationDetail',
        query: {
          id: item.id
        }
      })
    },

    // 查看更多
    moreClick() {
      // 点击时滚动条先回到顶部
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      const timeTop = setInterval(() => {
        document.body.scrollTop = document.documentElement.scrollTop = top -= 50;
        if (top <= 0) {
          clearInterval(timeTop);
        }
      }, 10);
      this.$router.push({name: 'informationList'});
    }
    
  }
}
</script>

<style scoped lang="scss">
  .bt-information {
    // background-image: url('../../assets/images/product-bg.png');
    // background-repeat: no-repeat;
    // background-size: cover;
    overflow: auto;
    background-color: #fff;
    padding-bottom: 60px;
  }

  .bt-information-list {
    height: 740px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    li {
      margin-right: 20px;
      width: calc(50% - 20px);
      height: 350px;
      overflow: hidden;
      position: relative;
      cursor: pointer;
      transition: all 0.4s;
      &:hover {
        .bt-information-image {
          transform: scale(1.01);
        }
      }
    }
    li:nth-child(1) {
      margin-bottom: 40px;
    }
    li:nth-child(3) {
      margin-right: 0;
      margin-left: 20px;
      height: 740px;
    }
    p {
      line-height: 24px;
      font-size: 14px;
    }
  }
  .bt-information-image {
    width: 100%;
    height: 100%;
    transition: all 0.4s;
  }

  .bt-information-text {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.2);
    color: #fff;
    z-index: 1;
  }
  .bt-information-text-container {
    padding-top: 20%;
    padding-left: 5%;
    width: 90%;
  }
  .bt-information-btn {
    float: right;
    padding: 8px 20px;
    color: $themes_color;
  }

@media Screen and (max-width: $screen_width) {
  .bt-information-list {
    height: auto;
    display: block;
    li {
      margin-right: 0;
      width: 100%;
    }
    li:nth-child(3) {
      margin-top: 40px;
      margin-left: 0;
      height: 350px;
    }
  }
}

</style>