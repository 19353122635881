
<template>
  <div class="layout">
    <el-container>
      <el-header :style="'background-color:' + (isHomeFirst ? '#fff' : primaryColor)"><Header></Header></el-header>
      <el-container style="overflow: auto">
        <el-container>
          <el-main class="bt-main-contianer">
            <div class="bt-main">
              <transition name="slide">
                <router-view></router-view>
              </transition>
              <Footer v-if="routerPath != 'home' && routerPath != '/'"></Footer>
            </div>
          </el-main>
        </el-container>
      </el-container>
    </el-container>
  </div>
</template>
<script>
import Header from "./header.vue";
import Footer from "./footer.vue";
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      breadcrumbItem: "",
      routerPath: "/",
    };
  },
  computed: {
    primaryColor() {
      return this.$store.state.primaryColor
    },
    isHomeFirst() {
      return this.$store.state.isHomeFirst
    }
  },
  mounted() {
    
  },
  watch: {
    // 监听路由 多次请求一个路由时不报错
    $route(to, from) {
      const toDepth = to.path.split("/").length;
      const fromDepth = from.path.split("/").length;
      this.transitionName = toDepth < fromDepth ? "slide-right" : "slide-left";
      this.routerPath = to.name;
    },
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.el-container {
  height: 100%;
}


.bt-main-contianer {
  margin-top: 60px;
  position: relative;
}

.el-header {
  position: fixed;
  top: 0;
  width: 100%;
  height: 60px;
  overflow: hidden;
  background-color: $themes_color;
  z-index: 99;
  transition: all 1s;
}
.el-main {
  padding: 0;
}

.slide-enter-active {
  transition: all 0.5s linear;
}
.slide-leave-active {
  transition: all 0.5s linear;
}
.slide-enter {
  transform: translateY(-200px);
  opacity: 0;
}
.slide-leave-to {
  transform: translateY(100px);
  opacity: 0;
}
</style>

<style lang="scss">
.el-header {
  overflow: inherit !important;
}
</style>