export const list = [
  {
    id: '0',
    logo: require('@/assets/images/yiruntong.svg'),
    name: '医疗耗材追溯管理系统',
    context: '让所有的产品都可追溯',
    erweimaAndroid: require('@/assets/images/yrtpro_android.png'),
    erweimaIOS: require('@/assets/images/yrtpro_ios.png'),
    applycation: [
      {name: '繁重的协调与采购工作'},
      {name: '证件管理'},
      {name: '纸质合规与进程管理'},
      {name: '耗材品类信息管理'},
      {name: '到货信息与质量追溯管理'},
    ],
    syetem: [
      {name: '分发与采购智能处理', icon: require('@/assets/images/yiruntong0.svg')},
      {name: '资证管理智能化', icon: require('@/assets/images/yiruntong1.svg')},
      {name: '交易数据图形化', icon: require('@/assets/images/yiruntong2.svg')},
      {name: '电子合同智能管控', icon: require('@/assets/images/yiruntong3.svg')},
    ],
    solve: [
      {
        name: '生产厂家',
        list: [
          {name: '生产厂家'},
          {name: '注销证号'},
          {name: '生产批号'},
          {name: '有效期'},
        ]
      },
      {
        name: '经销商',
        list: [
          {name: '营业执照'},
          {name: '经营许可证'},
          {name: '库存状态'},
          {name: '物流状态'},
        ]
      },
      {
        name: '医院',
        list: [
          {name: '库存状态'},
          {name: '到货状态'},
          {name: '资质证照'},
          {name: '相关记录'},
        ]
      },
      {
        name: '科室',
        list: [
          {name: '库存状态'},
          {name: '领用情况'},
        ]
      },
      {
        name: '患者',
        list: [
          {name: '质量情况'},
          {name: '来源'},
        ]
      }
    ]
  },
  {
    id: '1',
    logo: require('@/assets/images/yixiuku.svg'),
    name: '医疗设备全生命周期管理系统',
    context: '让医疗设备管理更简单',
    erweimaIOS: require('@/assets/images/yxkpro_ios.png'),
    erweimaAndroid: require('@/assets/images/yxkpro_android.png'),
    applycation: [
      {name: '满足各级医疗机构对医疗设备全流程'},
      {name: '全生命周期管理需求'},
      {name: '符合三级综合医院等级评审对信息化管理需求'},
    ],
    syetem: [
      {name: '设备管理'},
      {name: '维修管理'},
      {name: '保养管理'},
      {name: '巡检管理'},
      {name: '配件管理'},
      {name: '工程师管理'},
      {name: '申购管理'},
      {name: '报废管理'},
      {name: '合同管理'},
      {name: '供应商管理'},
      {name: '计量管理'},
      {name: '效益分析'},
      {name: '转科管理'},
      {name: '转单管理'},
      {name: '科室管理'},
      {name: '不良事件报告'},
    ],
    solve: [
      {
        name: '医疗设备全生命周期管理',
        list: [
          {name: '申购、采购、验收、启用、维修、保养、计量、质控、流转、报废所有信息均可追溯、云端存储，随时随地了解设备运行状态，并将档案永久云端保存'},
        ]
      },
    ]
  },
]