<template>
  <div class="footer">
    <ul class="bt-menu-list">
      <li v-for="(item, index) in menuList" :key="index">
        <div class="bt-menu-name">{{item.name}}</div>
        <div class="bt-menu-subName" 
          v-for="(child, childIndex) in item.children" :key="childIndex"
          @click="menuChange(child)">
          {{child.name}}
        </div>
      </li>
    </ul>
    <div class="bt-icp">
      <span>版权所有：云南栢田医疗科技有限公司</span>
      <span class="bt-icp-text">ICP备案号： <a href="https://beian.miit.gov.cn" target="_blank">滇ICP备2021007989号</a></span>
    </div>
  </div>
</template>
<script>
import { menuList } from '@/utils/menu.js'
export default {
  components: {
    
  },
  data() {
    return {
      menuList: [],
      gotop: false
    }
  },
  computed: {
    
  },
  mounted() {
    menuList.forEach(item => {
      if(item.children) {
        this.menuList.push(item);
      }
      
    });

    window.addEventListener("scroll", this.handleScroll, true);
    
  },
  methods: {
    handleScroll() {
       let scrolltop = document.documentElement.scrollTop || document.body.scrollTop;
      scrolltop > 30 ? (this.gotop = true) : (this.gotop = false);
    },

    // 列表点击
    menuChange(item) {
      // 点击时滚动条先回到顶部
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      const timeTop = setInterval(() => {
        document.body.scrollTop = document.documentElement.scrollTop = top -= 50;
        if (top <= 0) {
          clearInterval(timeTop);
        }
      }, 10);
      this.$router.push({
        name: item.path,
        query: {
          id: item.serviceId
        }
      });
    }

  }
}
</script>

<style scoped lang="scss">

.footer {
  border-top: 1px solid #eee;
  background-color: #fff;
  height: auto !important;
  padding: 10px 20px;
  text-align: center;
  font-size: 12px;
  color: #aaa;
}

.bt-menu-list {
  width: $min-width;
  margin: 0 auto;
  display: flex;
  padding: 20px;
  li {
    flex: 1;
    text-align: left;
  }
}

.bt-menu-name {
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: bolder;
  color: $themes_color;
}
.bt-menu-subName {
  line-height: 30px;
  font-size: 14px;
  color: #666;
  cursor: pointer;
  &:hover {
    color: $themes_color;
  }
}

.bt-icp {
  padding-top: 10px;
  padding-bottom: 10px;
  border-top: 1px solid #ccc;
  text-align: center;
  font-size: 14px;
  color: #666;
  .bt-icp-text {
    margin-left: 150px;
    color: $themes_color;
  }
  a {
    text-decoration: none;
    color: $themes_color;
  }
}


@media Screen and (max-width: $screen_width) {
.footer {
  padding: 1vh 2vh;
  font-size: 1.2vh;
}
.bt-menu-list {
  width: calc(100% - 4vh);
  padding: 2vh;
}
.bt-menu-name {
  font-size: 1.2vh;
}
.bt-menu-subName {
  margin-bottom: 0.6vh;
  line-height: 2.2vh;
  font-size: 1.2vh;
}
.bt-icp {
  font-size: 1.2vh;
  .bt-icp-text {
    margin-left: 0;
    display: block;
  }
}
.bt-menu-list {
  li:nth-child(2) {
    flex: 2;
  }
}

}

</style>