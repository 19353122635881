import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router);

import layout from '@/views/layout/index';
import home from '@/views/home/index';
import business from '@/views/business/index';
import businessDetail from '@/views/business/detail';
import products from '@/views/products/index';
import productsDetail from '@/views/products/detail';
import information from '@/views/information/index';
import informationList from '@/views/information/lists';
import video from '@/views/information/video';
import informationDetail from '@/views/information/detail';
import Case from '@/views/case/index';
import CaseDetail from '@/views/case/detail';
import recruit from '@/views/recruit/index';
import joinus from '@/views/recruit/joinus';
import contactus from '@/views/contactus/index';

export default new Router({
	// mode: 'history',// 默认hash
	base: "/",
	routes: [
		{ path: '/', component: layout, meta: { index: 0, name: '页面布局' }, 
			children: [
				{ path: '/', name: 'home', component: home, meta: { index: 1, name: '关于栢田' } },
				{ path: '/home', name: 'home', component: home, meta: { index: 1, name: '关于栢田' } },

				{ path: '/business', name: 'business', component: business, meta: { index: 1, name: '业务板块' } },
				{ path: '/businessDetail', name: 'businessDetail', component: businessDetail, meta: { index: 1, name: '详情' } },

				{ path: '/products', name: 'products', component: products, meta: { index: 1, name: '核心产品' } },
				{ path: '/productsDetail', name: 'productsDetail', component: productsDetail, meta: { index: 1, name: '详情' } },
				
				{ path: '/information', name: 'information', component: information, meta: { index: 1, name: '企业新闻' } },
				{ path: '/informationList', name: 'informationList', component: informationList, meta: { index: 1, name: '企业新闻列表' } },
				{ path: '/informationDetail', name: 'informationDetail', component: informationDetail, meta: { index: 1, name: '详情' } },
				{ path: '/video', name: 'video', component: video, meta: { index: 1, name: '企业视频' } },
				
				{ path: '/case', name: 'case', component: Case, meta: { index: 1, name: '案例分享' } },
				{ path: '/caseDetail', name: 'caseDetail', component: CaseDetail, meta: { index: 1, name: '详情' } },
				
				{ path: '/recruit', name: 'recruit', component: recruit, meta: { index: 1, name: '人才招聘' } },
				{ path: '/joinus', name: 'joinus', component: joinus, meta: { index: 1, name: '加入我们' } },

				{ path: '/contactus', name: 'contactus', component: contactus, meta: { index: 1, name: '联系我们' } },

			]
		},
	]
})

//获取原型对象上的push函数
const originalPush = Router.prototype.push
//修改原型对象中的push方法
Router.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)
}