export const list = [
  {
    id: '0',
    image: 'https://image.baitianjituan.com/case0.png',
    title: '《成为宣威市云峰医院全院耗材配送商》',
    context: '栢田医疗拥有超过15年全品类医疗耗材供应配送服务经验，是宣威市云峰医院的全院耗材配送商，在服务地区设立合规的医疗器械储存仓库，及时响应医疗机构的供货需求。',
  },
  {
    id: '1',
    image: 'https://image.baitianjituan.com/case1.png',
    title: '《搭建多地域异地耗材储备仓库，配送高效快速》',
    context: '在云南省唯一拥有认证通过的多地域异地耗材储备仓库，包括但不限于昆明市耗材仓库、昆明市骨科仓库、昆明药品试剂仓库、曲靖耗材仓库、宣威耗材仓库、宣威骨科仓库、宣威试剂仓库等，除此之外，还在会泽、富源、师宗、罗平、红河、大理、文山等多地设立仓库，保证高效配送。',
  },
  {
    id: '2',
    image: 'https://image.baitianjituan.com/case2.png',
    title: '《专业服务团队驻点，提供维保服务》',
    context: '由5年以上项目实施经验的项目负责人领导的专业服务团队驻点服务，在遵义市播州区人民医院驻点，提供全天候24小时服务，保证响应时效。',
  },
  {
    id: '3',
    image: 'https://image.baitianjituan.com/case3.png',
    title: '《定期检测、保养设备，保障设备安全有效》',
    context: '栢田医疗为河北省保定市第二医院提供专业服务团队驻点服务，制定预防性维修（保养）计划，定期对设备进行保养，在设备未发生故障前，发现潜在问题，提前解决及预防，保证设备维持在正常运行状态，延长使用寿命。',
  },
]