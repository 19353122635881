<template>
  <div class="bt-contactus" :style="'min-height:' + height + 'px'">
    <div class="bt-main-container">
      <div class="bt-title">联系我们<span class="bt-subtitle">CONTACT  US</span></div>
      <div class="bt-contactus-main">
        <el-image
            class="bt-contactus-image"
            :src="require('@/assets/images/baitian-erweima.png')"
            fit="fill"></el-image>
        <ul class="bt-contactus-list">
          <li>
            <i class="el-icon-phone-outline"></i>
            <span>400-874-8989</span>
          </li>
          <li class="is-iphone">
            <i class="el-icon-map-location"></i>
            <span>云南省五华区融城园城医药健康科技园17楼</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      height: window.innerHeight - 360,
      
    }
  },
  mounted() {
    
  },
  methods: {
    
    
  }
}
</script>

<style scoped lang="scss">
  .bt-contactus {
    background-image: url('../../assets/images/contactus-bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
  }

  .bt-contactus-main {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 1000px;
    height: 200px;
  }

  .bt-contactus-image {
    width: 200px;
    height: 200px;
    float: left;
    margin-right: 50px;
  }

  .bt-contactus-list {
    padding-top: 20px;
    li {
      margin-top: 30px;
      font-size: 24px;
      color: #666;
    }
    i {
      margin-right: 20px;
      font-size: 36px;
    }
  }

@media Screen and (max-width: $screen_width) {
  .bt-contactus-main {
    position: relative;
    width: 100%;
    height: auto;
    text-align: center;
  }
  .bt-contactus-image {
    margin-right: 0;
    float: inherit;
  }
  .bt-contactus-image {
    width: 20vh;
    height: 20vh;
    margin-right: 0;
  }
  .bt-contactus-list {
    margin: 0 auto;
    padding-bottom: 5vh;
    width: 60%;
    padding-top: 1vh;
    li {
      margin-top: 1vh;
      font-size: 1.6vh;
    }
    i {
      margin-right: 0.6vh;
      font-size: 1.8vh;
    }
  }
  .is-iphone {
    display: none;
  }
}


</style>