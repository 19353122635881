
<template>
  <div class="bt-business" :style="'min-height:' + height + 'px'">
    <div class="bt-main-container">
      <div class="bt-title">业务板块<span class="bt-subtitle">BUSINESS SEGMENT</span></div>
      <ul class="bt-business-list">
        <li v-for="(item, index) in businessList" :key="index" @click="businessChange(item)">
          <el-image
            :src="item.logo"
            fit="fill"></el-image>
          <div class="bt-context bt-overflow2">{{item.subName}}</div>
          <div class="bt-context bt-overflow2">{{item.name}}</div>
        </li>
      </ul>

    </div>
  </div>
</template>
<script>
import { list } from './list.js'
export default {
  data() {
    return {
      height: window.innerHeight - 60,
      businessList: list
    }
  },
  mounted() {
    
    
  },
  methods: {
    // 列表点击跳转到详情
    businessChange(item) {
      this.$router.push({
        name: 'businessDetail',
        query: {
          id: item.id
        }
      })
      // 点击时滚动条先回到顶部
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      const timeTop = setInterval(() => {
        document.body.scrollTop = document.documentElement.scrollTop = top -= 50;
        if (top <= 0) {
          clearInterval(timeTop);
        }
      }, 10);
    }
  }
}
</script>

<style scoped lang="scss">
  .bt-business {
    background-image: url('../../assets/images/business-bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom: 60px;
  }
  .bt-business-list {
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
    li {
      width: calc(25% - 30px);
      // flex: 1;
      margin: 15px;
      height: 660px;
      box-shadow: 0 12px 12px 0 rgba(0, 0, 0, 0.1);
      background-color: #fff;
      border-radius: 4px;
      text-align: center;
      overflow: hidden;
      cursor: pointer;
      transition: all 0.4s;
    }
    .el-image {
      margin-bottom: 20px;
      width:100%;
      height:500px;
      overflow:hidden;
      transition: all 0.4s;
    }
    .bt-context {
      padding: 10px;
      font-size: 17px;
      font-weight: bolder;
      color: $themes_color;
    }
    li:hover {
      box-shadow: 0 12px 12px 0 rgba(66, 48, 48, 0.3);
      // transform: rotateY(45deg);
      .el-image {
        transform: scale(1.01);
      }
    }

  }
@media Screen and (max-width: $screen_width) {
  .bt-business-list {
    margin-left: 0;
    margin-right: 0;
    li {
      margin: 10px 0;
      width: 100%;
    }
  }

}
</style>