<template>
  <div class="bt-product-detail" :style="'min-height:' + height + 'px'">
    <div class="bt-main-container">
      <div class="bt-title">核心产品<span class="bt-subtitle">CORE PRODUCTS</span></div>
      <div class="bt-container">
        <div class="bt-phone">
          <el-image
            class="bt-phone-image"
            :src="require('@/assets/images/phone.png')"
            fit="fill"></el-image>
          <div class="bt-phone-erweima">
            <el-image
              style="width:88px; height: 88px;"
              :src="info.erweimaIOS"
              fit="fill"></el-image>
              <div style="margin-bottom: 10px;">IOS</div>
            <el-image
              style="width:88px; height: 88px;"
              :src="info.erweimaAndroid"
              fit="fill"></el-image>
              <div style="margin-bottom: 10px;">Android</div>
          </div>
        </div>
        <div class="bt-structure">
          <div class="bt-structure-title">应用场景</div>
          <ul class="bt-applyction-list">
            <li v-for="(item, index) in info.applycation" :key="index">
              {{item.name}}
            </li>
          </ul>
          <div class="bt-structure-title">系统构成</div>
          <ul :class="info.syetem.length <= 4 ? 'bt-system-list' : 'bt-system-list-yxk'">
            <li v-for="(item, index) in info.syetem" :key="index">
              <el-image
                v-if="item.icon"
                class="bt-system-image"
                :src="item.icon"
                fit="fill"></el-image>
              <div class="bt-system-item">{{item.name}}</div>
            </li>
          </ul>
          <div class="bt-structure-title">解决方案</div>
          <ul :class="info.solve.length <= 3 ? 'bt-slove-list-yxk' : 'bt-slove-list'">
            <li v-for="(item, index) in info.solve" :key="index">
              <div class="bt-solve-name"><span>{{item.name}}</span></div>
              <ul class="bt-solve-item">
                <li v-for="(child, childIndex) in item.list" :key="childIndex">{{child.name}}</li>
              </ul>
            </li>
          </ul>

        </div>
      </div>
      
    </div>
  </div>
</template>
<script>
import { list } from './list.js';
export default {
  data() {
    return {
      height: window.innerHeight - 60,
      info: {
        erweima: require('@/assets/images/yrtpro_android.png'),
        applycation: [],
        syetem: [],
        solve: []
      },
    }
  },
  watch: {
    $route: {
      handler: function(val) {
        this.id = val.query.id;
        this.getDetailData();
      }
    }
  },
  created() {
    this.id = this.$route.query.id;
    this.getDetailData();
    
  },
  methods: {
    // 获取数据
    getDetailData() {
      list.forEach(item => {
        if(this.id == item.id) {
          this.info = item;
        }
      })
    },
    
  }
}
</script>

<style scoped lang="scss">
  .bt-product-detail {
    background-image: url('../../assets/images/product-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom: 60px;
  }

  .bt-container {
    display: flex;
    padding: 60px;
    box-shadow: 0px 1px 8px rgba(23, 52, 118, 0.2);
    background-color: #fff;
  }
  
  .bt-phone {
    position: relative;
    width: 350px;
    .bt-phone-erweima {
      position: absolute;
      top: 210px;
      left: 130px;
      width: 88px;
      height: 88px;
      text-align: center;
      font-size: 20px;
      color: #666;
    }
  }
  .bt-phone-image {
    width: 350px;
  }

  .bt-structure {
    margin-left: 120px;
  }
  .bt-structure-title {
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 24px;
    font-weight: bold;
    color: $themes_color;
  }
  
  .bt-applyction-list {
    width: 100%;
    li {
      margin-right: 30px;
      margin-bottom: 10px;
      padding: 0 30px;
      display: inline-block;
      background-color: #E6ECF5;
      height: 40px;
      line-height: 40px;
      font-size: 18px;
      color: $themes_color;
    }
  }

  // 系统构成 -- 医润通
  .bt-system-list {
    display: flex;
    li {
      flex: 1;
      text-align: center;
    }
    .el-image {
      margin-bottom: 8px;
    }
    .bt-system-item {
      margin: auto;
      width: 78px;
      font-size: 16px;
      color: #666;
    }
    li:nth-child(1) {
      .bt-system-item {
        width: 80px;
      }
    }
  }

  //系统构成 --医修库
  .bt-system-list-yxk {
    margin-left: -15px;
    margin-right: -15px;
    display: flex;
    flex-wrap: wrap;
    li {
      margin: 0 15px 14px 15px;
      width: calc(33.33% - 90px);
      height: 32px;
      line-height: 32px;
      padding: 0 30px;
      text-align: center;
      background-color: #E6ECF5;
      font-size: 18px;
      color: $themes_color;
    }
  }

  // 解决方案
  .bt-slove-list {
    display: flex;
    li {
      flex: 1;
      text-align: center;
    }
    .bt-solve-name {
      margin: 0 auto;
      width: 70px;
      height: 70px;
      line-height: 70px;
      border-radius: 70px;
      font-size: 15px;
      color: #fff;
      background-image: url('../../assets/images/solve.png');
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;
      &::after {
        content: '';
        position: absolute;
        top: 32px;
        right: -20px;
        display: inline-block;
        width: 0;
        height: 0;
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-left: 10px solid $themes_color;
      }
    }
    li:nth-child(1) {
      .bt-solve-name {
        span {
          display: inline-block;
          vertical-align: -8px;
          line-height: 20px;
          width: 40px;
        }
      }
    }
    li:nth-last-child(1) {
      .bt-solve-name::after {
        border-width: 0px;
      }
    }
    .bt-solve-item {
      margin-top: 10px;
      line-height: 30px;
      font-size: 14px;
      color: #666;
    }
  }
  .bt-system-image {
    width: 80px; 
    height: 80px
  }

  // 解决方案  -- 医修库
  .bt-slove-list-yxk {
    display: flex;
    li {
      flex: 1;
    }
    .bt-solve-name {
      height: 30px;
      line-height: 30px;
      background-color: $themes_color;
      border-radius: 15px;
      text-align: center;
      color: #fff;
    }
    .bt-solve-item {
      margin-top: 10px;
      font-size: 14px;
      color: #666;
    }
  }

@media Screen and (max-width: $screen_width) {
  .bt-container {
    display: block;
    padding: 0;
    background-color: transparent;
    box-shadow: 0 0 0;
  }
  .bt-phone {
    display: block;
    margin: 0 auto;
  }
  .bt-structure {
    margin-left: 0;
  }
  .bt-structure-title {
    font-size: 18px;
  }
  .bt-applyction-list li {
    padding: 0 10px;
    height: 30px;
    line-height: 30px;
    font-size: 14px;
  }
  .bt-system-list .bt-system-item {
    font-size: 12px;
  }
  .bt-slove-list .bt-solve-item {
    line-height: 24px;
    font-size: 12px;
  }
  .bt-system-image {
    width: 60px; 
    height: 60px
  }
  .bt-system-list-yxk {
    margin-left: -7px;
  }
  .bt-system-list-yxk li {
    margin: 0 8px 7px 8px;
    width: calc(33.333% - 40px);
    padding: 0 10px;
    font-size: 12px;
  }
  .bt-solve-item {
    font-size: 12px !important;
  }
}


</style>