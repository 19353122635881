/**
 * 时间戳转标准时间格式
 * @param {*} data 传入的时间戳
 * @param {*} type type = day时转单位为天，  type = date时单位为时分秒
 * @returns 输出标准时间格式
 */
  export function formatDate(data, type = 'day') {
    var date = new Date(parseInt(data));
    var YY = date.getFullYear() + "-";
    var MM = (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1) + "-";
    var DD = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    var hh = (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
    var mm = (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) + ":";
    var ss = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
    if (type == "date") {
      return YY + MM + DD + " " + hh + mm + ss;
    } else if (type == "day") {
      return YY + MM + DD;
    }
  }

  /**
   * 判断数值是否为空
   * @param {*} val 传入需要判断的数值
   * @returns 如果为空，输出true，如果不为空，输出false
   */
  export function isValNull(val) {
    if (val === "" || val === null || val === undefined || val === "null" || 
        val === "undefined" || JSON.stringify(val) == "{}" || JSON.stringify(val) == "[]") {
      return true;
    } else {
      return false;
    }
  }
  /**
 * 
 * @param {*} arr 数组
 * @param {*} idKey 每个节点的id属性名称
 */
export function arrayToTree(arr, idKey) {
  if (!arr) {
      return
  }
  //var jsonStr = JSON.stringify(arr);
  //let treeArr = arr;
  //let treeArr = JSON.parse(jsonStr);

  let treeArr = deepClone(arr);
  //遍历寻找根节点
  let pid = '';
  for (var i = 0; i < treeArr.length; i++) {
      var hasParent = false;
      for (var j = 0; j < treeArr.length; j++) {
          if (treeArr[i].parentID == treeArr[j][idKey]) {
              hasParent = true;
              break;
          }
      }
      if (!hasParent) {
          pid = treeArr[i].parentID;
          break;
      }
  }

  return recu(arr, pid, idKey);
}

/**
 * 代理法  深度拷贝对象
 * @param {obj} obj 
 */
 function deepClone(obj) {
  if (!isObject(obj)) {
      throw new Error('obj 不是一个对象！')
  }

  let isArray = Array.isArray(obj)
  let cloneObj = isArray ? [...obj] : {
      ...obj
  }
  Reflect.ownKeys(cloneObj).forEach(key => {
      cloneObj[key] = isObject(obj[key]) ? deepClone(obj[key]) : obj[key]
  })

  return cloneObj
}

/**
 * 判断是否为对象
 * @param {obj} o 
 */
 function isObject(o) {
  return (typeof o === 'object' || typeof o === 'function') && o !== null
}

var recu = function (arr, parentID, idKey) {
  if (idKey == undefined) {
      idKey = "id";
  }
  let temp = [];
  //let treeArr = arr;
  //var jsonStr = JSON.stringify(arr);
  //let treeArr = arr;
  //let treeArr = JSON.parse(jsonStr);
  let treeArr = deepClone(arr);
  treeArr.forEach((item, index) => {
      if (item.parentID == parentID) {

          if (recu(treeArr, treeArr[index][idKey], idKey).length > 0) {
              treeArr[index].children = recu(treeArr, treeArr[index][idKey], idKey);
          } else {
              treeArr[index].children = null
          }
          temp.push(treeArr[index]);
      }
  });
  return temp;
}